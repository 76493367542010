import * as React from "react";
import "./Community.css";
import banner from "../../asset/image/community/banner-1.svg";
import title from "../../asset/image/community/title.svg";
import { useHistory, useLocation } from "react-router-dom";

const Community = () => {
  const { search } = useLocation();

  const searchParams = React.useMemo(
    () => new URLSearchParams(search),
    [search]
  );

  React.useEffect(() => {
    console.log(searchParams.get("id"));
  }, [searchParams]);

  return (
    <div>
      <div className="image-box">
        <img alt="" src={banner} />
        <div className="community-text">
          <div>Community</div>
        </div>
      </div>

      <div style={{ maxWidth: "1500px", margin: "0 auto" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "80px 0 100px 0",
          }}
        >
          <img style={{ width: "15%" }} src={title} alt="" />
        </div>

        {searchParams.get("id") === null &&
          searchParams.get("edit") === null &&
          searchParams.get("write") === null && <CommunityList />}

        {searchParams.get("id") && <Post />}

        {searchParams.get("write") && <WritePost />}

        {searchParams.get("edit") && <EditPost />}
      </div>
    </div>
  );
};

export default Community;

const WritePost = () => {
  const history = useHistory();

  const edit = (id: any) => {
    history.push("/community?edit=" + id);
  };

  const moveToList = () => {
    history.push("/community");
  };

  return (
    <div className="board_wrap">
      <div className="board_title">
        <strong>community</strong>
      </div>
      <div className="board_write_wrap">
        <div className="board_write">
          <div className="title">
            <span
              style={{
                textAlign: "left",
                display: "inline-block",
                fontSize: "20px",
                width: "100px",
              }}
            >
              제목
            </span>
            <input type="text" placeholder="제목 입력" />
          </div>
          <div className="info">
            <dl>
              <dt>글쓴이</dt>
              <input
                type="text"
                placeholder="글쓴이 입력"
                style={{ width: "200px", fontSize: "16px" }}
              />
            </dl>
            <dl>
              <dt>비밀번호</dt>
              <input
                type="password"
                placeholder="비밀번호 입력"
                style={{ width: "200px", fontSize: "16px" }}
              />
            </dl>
          </div>
          <div className="cont">
            <input type="file" name="SelectFile" />
            <textarea placeholder="내용 입력"></textarea>
          </div>
        </div>
        <div className="bt_wrap">
          <div
            className="edit-btn"
            style={{ margin: "0" }}
            onClick={moveToList}
          >
            등록
          </div>
          <div
            className="cancel-btn"
            style={{ margin: "0" }}
            onClick={moveToList}
          >
            취소
          </div>
        </div>
      </div>
    </div>
  );
};

const EditPost = () => {
  const history = useHistory();

  const edit = (id: any) => {
    history.push("/community?edit=" + id);
  };

  const moveToList = () => {
    history.push("/community");
  };

  return (
    <div className="board_wrap">
      <div className="board_write_wrap">
        <div className="board_write">
          <div className="title">
            <span
              style={{
                textAlign: "left",
                display: "inline-block",
                fontSize: "20px",
                width: "70px",
              }}
            >
              제목
            </span>
            <input type="text" placeholder="제목 입력" />
          </div>
          <div className="info">
            <dl>
              <dt>글쓴이</dt>
              <input
                type="text"
                placeholder="글쓴이 입력"
                style={{ width: "200px", fontSize: "16px" }}
              />
            </dl>
            <dl>
              <dt>비밀번호</dt>
              <input
                type="password"
                placeholder="비밀번호 입력"
                style={{ width: "200px", fontSize: "16px" }}
              />
            </dl>
          </div>
          <div className="cont">
            <input type="file" name="SelectFile" />
            <textarea placeholder="내용 입력">
              이곳엔 글 내용이 들어갈것 같습니다.
            </textarea>
          </div>
        </div>
        <div className="bt_wrap">
          <div
            className="edit-btn"
            style={{ margin: "0" }}
            onClick={moveToList}
          >
            수정
          </div>
          <div
            className="cancel-btn"
            style={{ margin: "0" }}
            onClick={moveToList}
          >
            취소
          </div>
        </div>
      </div>
    </div>
  );
};

const Post = () => {
  const history = useHistory();

  const edit = (id: any) => {
    history.push("/community?edit=" + id);
  };

  const moveToList = () => {
    history.push("/community");
  };

  return (
    <div className="board_wrap">
      <div className="board_title">
        <p>YIG member's space</p>
      </div>
      <div className="board_view_wrap">
        <div className="board_view">
          <div className="title">글 제목이 들어갑니다.</div>
          <div className="info">
            <dl>
              <dt>번호</dt>
              <dd>1</dd>
            </dl>
            <dl>
              <dt>글쓴이</dt>
              <dd>홍길동</dd>
            </dl>
            <dl>
              <dt>작성일</dt>
              <dd>2023.02.29</dd>
            </dl>
            <dl>
              <dt>댓글수</dt>
              <dd>0</dd>
            </dl>
          </div>
          <div className="cont">
            이곳엔 글 내용이 들어갈것 같습니다. 혹시나 파일첨부 등 필요한 거
            있으시면 말씀 해 주세욥!
          </div>
        </div>

        <div className="comments">
          <ul id="comment-list"></ul>
          <form id="comment-form">
            <input
              type="text"
              id="comment-input"
              placeholder="댓글을 입력하세요"
            />
            <button className="register-btn" type="submit">
              등록
            </button>
          </form>
        </div>
        <script src="script.js"></script>
        <div className="bt_wrap">
          <div className="list-btn m-0" onClick={moveToList}>
            목록
          </div>
          <div
            className="edit-btn switch"
            style={{ margin: "0" }}
            onClick={() => edit(5)}
          >
            수정
          </div>
        </div>
      </div>
    </div>
  );
};

const CommunityList = () => {
  const history = useHistory();
  const [addCategory, setAddCategory] = React.useState(false);

  const [categoryList, setCategoryList] = React.useState([
    "운용팀",
    "해외투자팀",
    "42기 자기소개",
    "43기 자기소개",
    "공지사항",
    "웹사이트 피드백",
    "자유 게시판",
  ]);

  const [selected, setSelected] = React.useState("운용팀");

  const moveTo = (path: any) => {
    history.push("/community?id=" + path);
  };

  const moveToWrite = () => {
    history.push("/community?write=true");
  };

  return (
    <div className="comunity-list-box">
      <div style={{ width: "200px" }}>
        <div className="add-btn" onClick={() => setAddCategory(!addCategory)}>
          Add Category
        </div>

        <div
          className="category-add-box"
          style={addCategory ? { display: "flex" } : { display: "none" }}
        >
          <input className="category-input" />
          <div className="add-category-btn">add</div>
        </div>

        <div className="category-list">
          {categoryList.map((e, i) => (
            <div
              key={i}
              onClick={() => {
                setSelected(e);
              }}
            >
              {e}
            </div>
          ))}
        </div>
      </div>

      <div style={{flex: 1}}>
        <div
          style={{
            margin: "0 0 50px 0",
            fontSize: "30px",
            fontWeight: 700,
            lineHeight: "1",
            textAlign: "center",
          }}
        >
          {selected}
        </div>

        <div
          style={{
            margin: "0 0 30px 30px",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "1",
          }}
        >
          YIG member's space
        </div>
        <div >
          <table className="community-table">
            <thead>
              <tr>
                <td width={100}>번호</td>
                <td width={100}>게시판</td>
                <td>제목</td>
                <td>글쓴이</td>
                <td width={100}>작성일</td>
              </tr>
            </thead>
            <tbody>
              <tr onClick={() => moveTo(5)}>
                <td>5</td>
                <td>운용팀</td>
                <td>글 제목이 들어갑니다.</td>
                <td>홍길동</td>
                <td>2023.02.29</td>
              </tr>
              <tr onClick={() => moveTo(4)}>
                <td>4</td>
                <td>운용팀</td>
                <td>글 제목이 들어갑니다.</td>
                <td>홍길동</td>
                <td>2023.02.29</td>
              </tr>
              <tr onClick={() => moveTo(3)}>
                <td>3</td>
                <td>운용팀</td>
                <td>글 제목이 들어갑니다.</td>
                <td>홍길동</td>
                <td>2023.02.29</td>
              </tr>
              <tr onClick={() => moveTo(2)}>
                <td>2</td>
                <td>운용팀</td>
                <td>글 제목이 들어갑니다.</td>
                <td>홍길동</td>
                <td>2023.02.29</td>
              </tr>
              <tr onClick={() => moveTo(1)}>
                <td>1</td>
                <td>운용팀</td>
                <td>글 제목이 들어갑니다.</td>
                <td>홍길동</td>
                <td>2023.02.29</td>
              </tr>
            </tbody>
          </table>

          <div className="c-pagenation">
            <div className="arrow">{"<<"}</div>
            <div className="arrow">{"<"}</div>
            <div className="page current">1</div>
            <div className="page">2</div>
            <div className="page">3</div>
            <div className="arrow">{">"}</div>
            <div className="arrow">{">>"}</div>
          </div>

          <div className="post-btn" onClick={moveToWrite}>
            등록
          </div>
        </div>
      </div>
    </div>
  );
};
