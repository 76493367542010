import * as React from 'react';
import {Route, Switch} from "react-router-dom";
import {MainPage} from "./component/main/MainPage";
import YIG from "./component/about/AboutYIG";
import Post from "./component/board/Post";
// import Register from "./component/register/Register";
import Recruiting from "./component/recruiting/Recruiting";
import ResearchPage from "./component/research/ResearchPage";
import Members from "./component/members/Members";
import LoginPage from "./component/header/LoginPage";
import SignUpPage from "./component/header/SignUpPage";
import Community from "./component/community/Community";

export default class Routes extends React.Component {
    constructor(props:{}){
        super(props);
    }

    public render() {
        return (
            <Switch>
                <Route path={'/yig'} component={YIG} />
                <Route path={'/post/:id(\\d+)'} render={()=><Post type={'read'} />} />
                <Route path={'/post/:id(\\d+)/edit'} render={()=><Post type={'post'} />} />
                <Route path={'/post/create'}  render={()=><Post type={'post'} />} />
                {/* <Route path={'/register'}  component={Register} /> */}
                <Route exact={true} path={'/'} component={MainPage} />
                <Route path={'/recruiting'} component={Recruiting}/>
                <Route path={'/research'} component={ResearchPage}/>
                <Route path={'/members'} component={Members}/>
                <Route path={'/community'} component={Community}/>
                <Route path={'/login'} component={LoginPage}/>
                <Route path={'/signup'} component={SignUpPage}/>
            </Switch>
        )
    }
}