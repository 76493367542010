import * as React from "react";
import "./ResearchPage.css";
import research_page from "../../asset/image/research/research-head.svg";
import left_arrow from "../../asset/image/research/left-arrow.svg";
import right_arrow from "../../asset/image/research/right-arrow.svg";
import file_icon from "../../asset/image/research/file-icon.svg";
import title from "../../asset/image/research/title.svg";

interface IReport {}

const ResearchPage = () => {
  const [modal, setModal] = React.useState(false);
  const [post, setPost] = React.useState(false);

  const [report, setReport] = React.useState<any>({});

  const toggleModal = (status: boolean | ((prevState: boolean) => boolean)) => {
    const myDom = document.querySelector("html");
    if (myDom !== null) {
      switch (status) {
        case true:
          setModal(status);
          myDom.style.overflow = "hidden";
          myDom.scrollTop = 0;
          break;
        case false:
          setModal(status);
          myDom.style.overflow = "";
          break;
      }
    }
  };

  const clickReport = (e: any) => {
    setPost(true);
    setReport(e);
  };

  return (
    <div className="research-page">
      <div className="research-image-box">
        <img alt="" src={research_page} />
        <div className="research-text">Research</div>
      </div>

      <div style={{ maxWidth: "1500px", margin: "0 auto" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "80px 0 100px 0",
          }}
        >
          <img src={title} alt="" />
        </div>

        <div className="research-info-box">
          <div className="table-box">
            {post ? (
              <>
                <div className="post-box">
                  <div className="post-title">
                    <div>제목: {report.title}</div>
                    <div>작성자: {report.name}</div>
                  </div>
                  <a href={report.url} target="_blank">
                    <div className="post-file-box">
                      <img src={file_icon} alt="" />
                      <div className="post-file-name">{report.fileName}</div>
                    </div>
                  </a>

                  <div className="post-button-box">
                    <div
                      className="post-back-button"
                      onClick={() => setPost(false)}
                    >
                      뒤로가기
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <table className="research-table">
                  <thead className="reaserach-head">
                    <tr>
                      <td>번호</td>
                      <td>제목</td>
                      <td>작성자</td>
                      <td>날짜</td>
                    </tr>
                  </thead>
                  <tbody>
                    {itemList.map((e, i) => (
                      <Item
                        key={i}
                        data={e}
                        handleClick={() => clickReport(e)}
                      />
                    ))}
                  </tbody>
                </table>

                <div className="post-button" onClick={() => toggleModal(true)}>
                  글쓰기
                </div>

                <div className="pagenation">
                  <img alt="" src={left_arrow} />
                  <div style={{ color: "black" }}>1</div>
                  <div>2</div>
                  <div>3</div>
                  <img alt="" src={right_arrow} />
                </div>
              </>
            )}
          </div>
        </div>

        <div
          className="modal"
          style={modal ? { display: "block" } : { display: "none" }}
        >
          <div className="background"></div>
          <div className="content">
            {/* <div className="modal-title">글쓰기</div> */}
            <div className="modal-input-box">
              <div className="modal-input-title">작성자</div>
              <input className="modal-input" placeholder="이름을 작성하세요" />
            </div>
            <div className="modal-input-box">
              <div className="modal-input-title">제목:</div>
              <input className="modal-input" placeholder="제목을 작성하세요" />
            </div>

            <div className="modal-file-upload-box">
              <div></div>
              {/* 
              <div className="modal-file-upload-text1">Upload a file</div>
              <div className="modal-file-upload-text2">
                Attach the file below
              </div> */}

              <div className="modal-file-upload">
                <input type="file" name="SelectFile" />
                {/* <img src={} alt='' /> */}
                {/* <div className="modal-file-upload-text3">file upload.</div> */}
                {/* {<div className="modal-file-upload-text4">
             file upload
                </div>
                <div className="modal-file-upload-text5">clicking here</div> */}
              </div>

              <div className="modal-button-box">
                <div
                  className="modal-cancel-button"
                  onClick={() => toggleModal(false)}
                >
                  Cancel
                </div>
                <div className="modal-upload-button">Upload File</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResearchPage;

const Item = ({ data, handleClick }: { data: any; handleClick: any }) => {
  return (
    <>
      <tr className="item-tr" onClick={handleClick}>
        <td>{data.number}</td>
        <td>{data.title}</td>
        <td>{data.name}</td>
        <td>{data.date}</td>
      </tr>
      <div></div>
      <div className="item-div" onClick={handleClick}>
        <div>
          <div style={{ marginBottom: "10px" }}>{data.title}</div>
          <div style={{ fontSize: "14px" }}>{data.date}</div>
        </div>
        <div className="item-div-name">{data.name}</div>
      </div>
    </>
  );
};

const itemList = [
  {
    number: "01",
    title: "케이아이엔엑스 (093320, KQ)",
    name: "문성윤, 신승민, 이상윤",
    date: "2024.02.24",
    fileName: "케이아이엔엑스 (093320, KQ)",
    url: "https://storage.googleapis.com/yighub-public/reports/YIG_%E1%84%8F%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8B%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%8B%E1%85%A6%E1%86%AB%E1%84%8B%E1%85%A6%E1%86%A8%E1%84%89%E1%85%B3.pdf",
  },
  {
    number: "02",
    title: "아이센스 (099190, KQ)",
    name: "박재연, 박정하, 이동규",
    date: "2024.02.24",
    fileName: "아이센스 (099190, KQ)",
    url: "https://storage.googleapis.com/yighub-public/reports/YIG_%E1%84%8B%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%89%E1%85%A6%E1%86%AB%E1%84%89%E1%85%B3.pdf",
  },
  {
    number: "03",
    title: "브이티 (018290, KQ)",
    name: "양희승, 윤재혁, 이지원",
    date: "2024.02.24",
    fileName: "브이티 (018290, KQ)",
    url: "https://storage.googleapis.com/yighub-public/reports/YIG_%E1%84%87%E1%85%B3%E1%84%8B%E1%85%B5%E1%84%90%E1%85%B5.pdf",
  },
  {
    number: "04",
    title: "제이앤티씨 (204270, KQ)",
    name: "문성윤, 박정하, 이지원, 주민성",
    date: "2024.02.24",
    fileName: "제이앤티씨 (204270, KQ)",
    url: "https://storage.googleapis.com/yighub-public/reports/YIG_%E1%84%8C%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8B%E1%85%A2%E1%86%AB%E1%84%90%E1%85%B5%E1%84%8A%E1%85%B5.pdf",
  },
  {
    number: "05",
    title: "바디텍메드(206640 KQ)",
    name: "문성윤, 박재연, 신승민, 양희승, 이상윤",
    date: "2024.02.24",
    fileName: "바디텍메드(206640 KQ)",
    url: "https://storage.googleapis.com/yighub-public/reports/YIG_%E1%84%87%E1%85%A1%E1%84%83%E1%85%B5%E1%84%90%E1%85%A6%E1%86%A8%E1%84%86%E1%85%A6%E1%84%83%E1%85%B3.pdf",
  },
];
