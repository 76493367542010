import * as React from "react";
import MainPage_1 from "./MainPage_1";
import MainPage_2 from "./MainPage_2";
import MainPage_3 from "./MainPage_3";
import MainPage_3_1 from "./MainPage_3_1";
import MainPage_4 from "./MainPage_4";
import MainPage_5 from "./MainPage_5";
import MainPage_6 from "./MainPage_6";

interface IState {
  fullSizeStyle: { width?: string; height?: string };
}

export class MainPage extends React.Component<{}, IState> {
  private mainRef: HTMLElement | null;
  private currentRef: HTMLElement | null;
  private subRefs: Array<HTMLElement | null>;
  private isScrolling: boolean;
  private currentSectionIndex: number;
  private touchStartYPositions: number[];

  constructor(props: {}) {
    super(props);
    this.state = {
      fullSizeStyle: {
        width: undefined,
        height: undefined,
      },
    };
    this.mainRef = null;
    this.currentRef = null;
    this.subRefs = [];
    this.isScrolling = false;
    this.currentSectionIndex = 0;
    this.touchStartYPositions = [];
  }

  public componentDidMount(): void {
    this.calFullSizeStyles();
    this.blockScrollMove();
    window.addEventListener("mousewheel", this.scrollEvent);
    this.addTouchEvent();
    this.scrollToTop();
    window.addEventListener("load", this.scrollToTop);
    window.addEventListener("beforeunload", this.scrollToTop);
  }

  private scrollToTop = () => {
    setTimeout(() => window.scrollTo(0, 0), 100);
  };

  private calFullSizeStyles = () => {
    if (this.mainRef) {
      this.setState({
        fullSizeStyle: {
          width: "100vw",
          height: `calc(100vh)`,
        },
      });
    }
  };

  public componentWillUnmount(): void {
    this.revertScrollMove();
    window.removeEventListener("mousewheel", this.scrollEvent);
    this.removeTouchEvent();
  }

  private blockScrollMove = () => {
    const body = document.body;
    body.style.overflowY = "hidden";
  };

  private revertScrollMove = () => {
    const body = document.body;
    body.style.overflowY = "auto";
  };

  private scrollEvent = (e: any) => {
    if (this.isScrolling) {
      return;
    }

    this.isScrolling = true;

    const deltaY = e.deltaY;

    setTimeout(() => {
      if (deltaY > 0) {
        this.scrollToNext(e);
      } else {
        this.scrollToPrev(e);
      }

      setTimeout(() => {
        this.isScrolling = false;
      }, 400);
    }, 400);
  };

  private addTouchEvent = () => {
    window.addEventListener("touchstart", this.touchStartEvent);
    window.addEventListener("touchend", this.touchEvent);
  };

  private removeTouchEvent = () => {
    window.removeEventListener("touchstart", this.touchStartEvent);
    window.removeEventListener("touchend", this.touchEvent);
  };

  private touchEvent = (e: TouchEvent) => {
    if (this.isScrolling) {
      return;
    }

    const startPosition = this.touchStartYPositions.pop();

    if (startPosition == null) {
      return;
    }

    const endPosition = e.changedTouches[0].clientY;
    const deltaY = startPosition - endPosition;

    if (deltaY === 0) {
      return;
    }

    this.isScrolling = true;

    setTimeout(() => {
      if (deltaY > 0) {
        this.scrollToNext(e);
      } else {
        this.scrollToPrev(e);
      }

      setTimeout(() => {
        this.isScrolling = false;
      }, 400);
    }, 300);
  };

  private touchStartEvent = (e: TouchEvent) => {
    const startPosition = e.touches[0].clientY;
    this.touchStartYPositions.push(startPosition);
  };

  private scrollToPrev = (e: Event) => {
    const currentIndex = this.currentSectionIndex;

    if (currentIndex === 0) {
      return;
    }

    const prevIndex = currentIndex - 1;

    const target = this.subRefs[prevIndex];

    if (target == null) {
      return;
    }

    if (this.mainRef != null) {
      const targetTop = target.getBoundingClientRect().top;
      const beforeTop = this.getNumberTopOfElement(this.mainRef);
      const marginTop = Math.min(beforeTop - targetTop, 0);

      this.mainRef.style.transition = "margin-top 0.5s";
      this.mainRef.style.transitionTimingFunction = "ease-in";
      this.mainRef.style.marginTop = marginTop + "px";
      this.currentSectionIndex = prevIndex;
    }
  };

  private scrollToNext = (e: Event) => {
    const currentIndex = this.currentSectionIndex;

    if (currentIndex === this.subRefs.length - 1) {
      this.isScrolling = false;
      return;
    }

    const nextIndex = currentIndex + 1;

    const target = this.subRefs[nextIndex];

    if (target == null) {
      return;
    }

    if (this.mainRef != null) {
      const targetTop = target.getBoundingClientRect().top;
      const beforeTop = this.getNumberTopOfElement(this.mainRef);
      const marginTop = beforeTop - targetTop;

      const sectionLength = this.subRefs.length;

      const totalHeightWithOutLastSection = this.subRefs
        .filter((ref, idx) => idx < sectionLength - 1)
        .map((ref) => ref!.getBoundingClientRect().height)
        .reduce((a, b) => a + b);

      this.mainRef.style.transition = "margin-top 0.5s";
      this.mainRef.style.transitionTimingFunction = "ease-in";
      this.mainRef.style.marginTop =
        Math.max(marginTop, -totalHeightWithOutLastSection) + "px";
      this.currentSectionIndex = nextIndex;
    }
  };

  public render() {
    return (
      <div
        ref={(ref) => {
          this.mainRef = ref;
        }}
      >
        {this.MainPage_1()}
        {this.MainPage_2()}
        {this.MainPage_3()}
        {this.MainPage_3_1()}
        {this.MainPage_4()}
        {this.MainPage_5()}
        {this.MainPage_6()}
      </div>
    );
  }

  private MainPage_1 = () => {
    return (
      <div
        style={{ ...this.state.fullSizeStyle }}
        ref={(ref) => (this.subRefs[0] = ref)}
      >
        <MainPage_1 />
      </div>
    );
  };

  private MainPage_2 = () => {
    return (
      <div
        style={{ ...this.state.fullSizeStyle }}
        ref={(ref) => (this.subRefs[1] = ref)}
      >
        <MainPage_2 />
      </div>
    );
  };

  private MainPage_3 = () => {
    return (
      <div
        style={{ ...this.state.fullSizeStyle }}
        ref={(ref) => (this.subRefs[2] = ref)}
      >
        <MainPage_3 />
      </div>
    );
  };

  private MainPage_3_1 = () => {
    return (
      <div
        style={{ ...this.state.fullSizeStyle }}
        ref={(ref) => (this.subRefs[3] = ref)}
      >
        <MainPage_3_1 />
      </div>
    );
  };

  private MainPage_4 = () => {
    return (
      <div
        style={{ ...this.state.fullSizeStyle }}
        ref={(ref) => (this.subRefs[4] = ref)}
      >
        <MainPage_4 />
      </div>
    );
  };

  private MainPage_5 = () => {
    return (
      <div
        style={{ ...this.state.fullSizeStyle }}
        ref={(ref) => (this.subRefs[5] = ref)}
      >
        <MainPage_5 />
      </div>
    );
  };

  private MainPage_6 = () => {
    return (
      <div ref={(ref) => (this.subRefs[6] = ref)}>
        <MainPage_6 />
      </div>
    );
  };

  private getNumberTopOfElement = (ref: HTMLElement): number => {
    const targetString = ref.style.marginTop;
    const targetNumber = Number(
      targetString.substring(0, targetString.length - 2)
    );
    return targetNumber;
  };
}
