import * as React from "react";
import "./Footer.css";
import logo_main from "../../asset/image/logo.svg";
import logo_text from "../../asset/image/logo-text.svg";
import kakaotalk_icon from "../../asset/image/footer/kakaotalk-icon.svg";
import instagram_icon from "../../asset/image/footer/instagram-icon.svg";

const Footer = () => {
  return (
    <div className="Footer">
      <div className="footer-left">
        <div className="footer-logo-box">
          <div>
            <img src={logo_main} alt="" />
          </div>
          <div>
            <img src={logo_text} alt="" />
          </div>
        </div>
        <div>
          Copyright ⓒ 2003 Yonsei Investment Group. All rights are reserved.
        </div>
      </div>
      <div className="footer-right">
        <div>지도교수 : 한재훈</div>
        <div>회장 : 신승민 (010-8794-2765)</div>
        <div>부회장 : 윤재혁 (010-6688-1485)</div>
        <div>총무/HR : 박재연 (010-2588-0992)</div>
        <div>이 메 일 : yigyonsei@gmail.com </div>
        <div className="icon-box">
          <img src={kakaotalk_icon} alt="" />
          <img src={instagram_icon} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
