import * as React from 'react';
import {RouteComponentProps, withRouter} from "react-router";
import './Gnb.css';
import Login from "./Login";

interface IGnb {
    name: string,
    className: string,
    active: () => boolean,
    onClick: () => void
}

interface IState {
    gnbs: IGnb []
}

class Gnb extends React.Component<RouteComponentProps<{}>, IState> {
    private gnbRefs: Map<string, HTMLDivElement | null>;

    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            gnbs: [
                {
                    name: 'Home',
                    className: 'gnbItem',
                    active: () => (this.props.location.pathname === '/'),
                    onClick: () => {
                        this.props.history.push('/')
                    }
                },
                {
                    name: 'About YIG',
                    className: 'gnbItem',
                    active: () => (this.props.location.pathname === '/yig'),
                    onClick: () => {
                        // this.props.history.push('/yig/introduction')
                    }
                },
                {
                    name: 'Members',
                    className: 'gnbItem',
                    active: () => (this.props.location.pathname === '/members'),
                    onClick: () => {
                        this.props.history.push('/members')
                    }
                },
                {
                    name: 'Research',
                    className: 'gnbItem',
                    active: () => (this.props.location.pathname === '/research'),
                    onClick: () => {
                        this.props.history.push('/research')
                    }
                },
                {
                    name: 'Recruiting',
                    className: 'gnbItem',
                    active: () => (this.props.location.pathname === '/recruiting'),
                    onClick: () => {
                        this.props.history.push('/recruiting')
                    }
                },
                {
                    name: 'Community',
                    className: 'gnbItem',
                    active: () => (this.props.location.pathname === '/community'),
                    onClick: () => {
                        this.props.history.push('/community')
                    }
                },
                {
                    name: 'Login',
                    className: 'gnbItem login-button',
                    active: () => (this.props.location.pathname === '/login'),
                    onClick: () => {
                        this.props.history.push('/login')
                    }
                },
            ]
        };

        this.gnbRefs = new Map<string, HTMLDivElement | null>()
    }
    
    public componentDidMount(): void {
        this.toggleGnbTextColor();
        window.addEventListener('scroll',this.toggleGnbTextColor)
    }

    public componentDidUpdate(prevProps: Readonly<RouteComponentProps<{}>>, prevState: Readonly<IState>, snapshot?: any): void {
        if(prevProps.location.pathname !== this.props.location.pathname) {
            this.toggleGnbTextColor()
        }
    }

    public moveTo(path: any): void {
        this.props.history.push(path);
    }

    public render() {
        return (
            <div className={"GnBWrapper"}>
                {this.state.gnbs.map((gnb, i) => (
                    <div key={i}>
                        <div
                            key={gnb.name}
                            className={gnb.className}
                            onClick={gnb.onClick}
                        >
                            <div ref={ref => this.gnbRefs.set(gnb.name, ref)}>{gnb.name}</div>
                            {
                                gnb.name === 'About YIG' ? 
                                <div className='dropdown'>
                                    <div onClick={()=> {this.moveTo('/yig/introduction')}}>Introduction</div>
                                    <div onClick={()=> {this.moveTo('/yig/curriculum')}}>Curriculum</div>
                                    <div onClick={()=> {this.moveTo('/yig/achievement')}}>Achievement</div>
                                </div>
                                :
                                <></>
                            }
                        </div>
                    </div>
                ))}
                {/*<Menu.Menu position='right'>*/}
                {/*    <Login/>*/}
                {/*</Menu.Menu>*/}
            </div>
        )
    }

    private toggleGnbTextColor = () => {
        const isWindowScrollIsTop = () => {
            return window.scrollY === 0
        };

        const headerChangeEvent = () => {
            if (!isWindowScrollIsTop()) {
                const gnbs = Array.from(this.gnbRefs).forEach((g) => {
                    if (g[1] != null) {
                        g[1].classList.remove('whiteText')
                    }
                })
            }

            if (isWindowScrollIsTop() && this.props.location.pathname === '/') {
                const gnbs = Array.from(this.gnbRefs).forEach((g) => {
                    if (g[1] != null) {
                        g[1].classList.add('whiteText')
                    }
                })
            }

            if (isWindowScrollIsTop() && this.props.location.pathname !== '/') {
                const gnbs = Array.from(this.gnbRefs).forEach((g) => {
                    if (g[1] != null) {
                        g[1].classList.remove('whiteText')
                    }
                })
            }
        };

        const addClassName = (element: HTMLElement, className: string) => {
            element.classList.add(className)
        };

        const removeClassName = (element: HTMLElement, className: string) => {
            element.classList.remove(className)
        };
        
        headerChangeEvent()
    };
}


export default withRouter(Gnb)

