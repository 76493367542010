import React, {useEffect, useState} from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import api, {IPostDTO} from '../../api';
import {RouteComponentProps, withRouter} from "react-router";

interface IPostImage {
    postId: number,
    name: string,
    image: File
}

const usePostImageUpload = () => {
    const [postImages, setPostImages] = useState<IPostImage []>([]);
    
    const uploadPostImage = async (postId: number, name: string, image: File) => {
        try {
            const {data} = await api.createPostImage(postId, name, image);
            return data
        } catch (e) {
            throw new Error("")
        }
    };
    
    return {
        postImages,
        setPostImages,
        uploadPostImage,
    }

};

const usePost = () => {
    const [title, setTitle] = useState<string | undefined>(undefined);
    const [content, setContent] = useState<string | undefined>(undefined);
    const {postImages, setPostImages, uploadPostImage } = usePostImageUpload();
    
    const getPost = async (id: number) => {
        try {
            return {
                title: 'tiutle',
                content: '<div>csdsd</div>',
                images: postImages
            }
        } catch (e) {
            throw new Error("cant get post")
        }
    };

    const createPost = async (postDTO: IPostDTO) => {
        try {
            const post = await api.createPost(postDTO);
            return post.data
        } catch (e) {
            throw new Error("cant create post")
        }
    };

    const updatePost = async (id: number, postDTO: IPostDTO) => {
        try {
            const post = await api.updatePost(id, postDTO);
            return post.data
        } catch (e) {
            throw new Error("cant update post")
        }
    };

    const deletePost = async (id: number) => {
        try {
            const post = await api.deletePost(id);
            return post.data
        } catch (e) {
            throw new Error("cant delete post")
        }
    };

    return {
        title,
        setTitle,
        content,
        setContent,
        getPost,
        createPost,
        updatePost,
        deletePost,
        uploadPostImage, 
    }
};

interface IPostProps extends RouteComponentProps<{ id?: string }> {
    type: 'read' | 'post'
}

const Post = (props: IPostProps) => {
    const {history, location, match, type} = props;
    const params = match.params;
    const postId = params.id != null ? Number(params.id) : undefined;
    const [id, setId] = useState(postId);
    const [editable, setEditable] = useState(type !== 'read');
    const {title, setTitle, content, setContent, getPost, createPost, deletePost, updatePost, uploadPostImage} = usePost();
    
    useEffect(() => {
        initializePost(id)
    }, [id]);

    const initializePost = (postId?: number) => {
        if (postId == null) {
            setTitle('');
            setContent('');
            return
        }
        const post = getPost(postId).then((post) => {
            setTitle(post.title);
            setContent(post.content)
        });
    };
    const test = () => {
        
    };

    const modules = {
        toolbar: [
            [{'header': [1, 2, false]}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image'],
            ['clean']
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ];

    return (
        <div className={'PageWrapper'}>
            <ReactQuill theme="snow"
                        style={{minHeight: '700px'}}
                        value={content}
                        onChange={setContent}
                        modules={modules}
                        formats={formats}/>
        </div>
    );
};

export default withRouter(Post)