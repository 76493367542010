import * as React from 'react';
import {PropsWithChildren} from "react";
import LocalStorageManager from "../util/LocalStorageManager";
// import AuthManager from "../AuthManager";


export interface IUserInfo {
    id: number,
    email: string,
    name: string,
    password?: string,
}

export const AuthContext = React.createContext({});


interface IState {
    userInfo?: IUserInfo;
    token?: string;
    isLogin: boolean;
}

interface IUserContextComponent {
    setToken: (token: string) => void;
    getUserInfo: () => IUserInfo | null;
    setUserInfo: (userInfo: IUserInfo) => void;
}

export default class AuthContextProvider extends React.Component<{}, IState> implements IUserContextComponent{
    constructor(props: {}){
        super(props);
        this.state = {
            isLogin: false
        };

        // if(AuthManager.isTokenExist()) {
        //     this.state = {
        //         token: AuthManager.getToken(),
        //         isLogin: false
        //     }
        // }
    }

    public async componentDidMount() {
        if(!this.state.isLogin && this.state.token != null){
            const userInfo = await this.fetchUserInfo();
            this.setUserInfo(userInfo);
            this.setLoginState(true);
        }
    }

    public render(){
       return (
           <AuthContext.Provider value={{
               userInfo: this.state.userInfo,
               getUserInfo: this.getUserInfo,
               setUserInfo: this.setUserInfo,
               refreshUserInfo: this.refreshUserInfo,
           }}>
               {this.props.children}
           </AuthContext.Provider>
       )
    };

    public setUserInfo = (userInfo: IUserInfo) => {
        // AuthManager.setUserInfo(userInfo);
        this.setState({userInfo})
    };

    public getUserInfo = () => {
        return this.state.userInfo || null;
    };

    private fetchUserInfo = async (): Promise<IUserInfo> => {
        return {
            id: 1,
            email: '',
            name: '',
        }
    };

    public refreshUserInfo = async ():Promise<IUserInfo | undefined> => {
        const userInfo = await this.fetchUserInfo();
        this.setUserInfo(userInfo);
        this.setLoginState(true);

        return userInfo
    };

    public setToken = (token: string) => {
        this.setState({token, isLogin: true})
    };

    private setLoginState = (state: boolean) => {
        this.setState({isLogin: state})
    };
}

