

export default class IntersectionObserverManager {
    private root?: HTMLElement;
    private rootMargin?: string;
    private threshold?: number;
    private intersectionObserver: IntersectionObserver;
    constructor(
        callback: IntersectionObserverCallback,
        options?:{
            root?: HTMLElement,
            rootMargin?: string,
            threshold?: number
    }){
        this.root = options == null ? undefined : options.root;
        this.rootMargin = options == null ? undefined : options.rootMargin;
        this.threshold = options == null ? undefined : options.threshold;
        this.intersectionObserver = new IntersectionObserver(callback)
    }

    public observe = (target: HTMLElement) => {
        this.intersectionObserver.observe(target)
    };

    public unObserve = (target: HTMLElement) => {
        this.intersectionObserver.unobserve(target)
    };

    public disconnect = () => {
        this.intersectionObserver.disconnect()
    }

}