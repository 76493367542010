import * as React from 'react';
import main_1 from "../../asset/image/yig/introduction/main-1.svg";
import main_2 from "../../asset/image/yig/introduction/main-2.svg";
import {ScrollAnimationContainer} from "./ScrollAnimationContainer";

const Introduction = () => {

    return (
        <div style={{maxWidth: '1500px', margin: '0 auto'}}>
            <ScrollAnimationContainer>
                <div style={{display: 'flex', justifyContent: 'center', margin: '80px 15vw 200px 15vw', width: '100%'}}>
                    <img style={{width: '100%'}} src={main_1} alt='' />
                </div>    
            </ScrollAnimationContainer>

            <ScrollAnimationContainer>
                <div style={{display: 'flex', justifyContent: 'center', margin: '80px 0 0 0'}}>
                    <img style={{width: '100%'}} src={main_2} alt='' />
                </div>  
            </ScrollAnimationContainer>
              
        </div>
    ) 
}

export default Introduction;