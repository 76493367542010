import * as React from 'react';
import './Members.css';
// import folder from '../../asset/image/members/member_list';

import {withRouter} from "react-router-dom";
import banner from '../../asset/image/members/Banner-1.svg';
import title from '../../asset/image/members/title.svg';
import empty from '../../asset/image/members/empty-box.svg';

const semesters = 43;

const AboutYIG = () => {
    const [selected, setSelected] = React.useState(1);
    const [memberList, setMemberList] = React.useState([]);

    const [drop, setDrop] = React.useState(false);


    const handleImgError = (e: any) => {
        e.target.src = empty;
    }

    const renderDropDown = () => {
        const result = [];
        for(let i = 0; i < semesters; i++){
            result.push(
                <div key={i} onClick={() => {
                    setSelected(i+1);
                    setDrop(false);
                }}>{i+1}기</div>
            )
        }
        return result;
    }

    React.useEffect(() => {
        setMemberList(list[selected] || []);
    }, [selected])


    return (
        <div>
            <div className='image-box'>
                <img alt='' src={banner} />
                <div className='member-title'>
                    <div>Members</div>
                </div>
            </div>
            <div className='member-box' style={{maxWidth: '1100px', margin: '0 auto'}}>
                
                <div style={{display: 'flex', justifyContent: 'center', margin: '80px 0 100px 0'}}>
                    <img style={{width: '20%'}} src={title} alt='' />
                </div> 

                <div className='select-wrap'>
                    <div className='select-box' onClick={() => setDrop(!drop)}>{selected}기</div>
                    <div className='select-list' style={
                        drop ? {display: 'block'} : {display: 'none'}
                    }>
                        {renderDropDown()}
                    </div>
                </div>

                <div className='members'>
                    {
                        memberList.map((e: any, i) => (
                            <div key={i}>
                                <div className='member-image-box'>
                                    <img 
                                    className='member-image'
                                    src={e.url} 
                                    onError={handleImgError} 
                                    alt='' 
                                    />
                                </div>
                                
                                <div className='member-info'>
                                    <div className='name'>{e.name.split('-')[1]}</div>
                                    <div>{e.class}</div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default withRouter(AboutYIG)

const list: any = {
    1: [
      {
        name: "1-정윤규",
        class: "상경계열 98학번",
        url: "",
      },
      {
        name: "1-류지혜",
        class: "경영학과 01학번",
        url: "",
      },
      {
        name: "1-김영환",
        class: "경영학과 01학번",
        url: "",
      },
      {
        name: "1-조가람",
        class: "경영학과 01학번",
        url: "",
      },
      {
        name: "1-김경무",
        class: "산업공학과 99학번",
        url: require(`../../asset/image/members/member_list/1기/1-김경무.png`),
      },
      {
        name: "1-구요한",
        class: "건축공학과 97학번",
        url: "",
      },
      {
        name: "1-이희정",
        class: "경영학과 00학번",
        url: "",
      },
    ],
    2: [
      {
        name: "2-강재형",
        class: "경영학과 99학번",
        url: "",
      },
      {
        name: "2-김은미",
        class: "경영학과 99학번",
        url: "",
      },
      {
        name: "2-심양섭",
        class: "경영학과 95학번",
        url: require(`../../asset/image/members/member_list/2기/2-심양섭.jpeg`),
      },
      {
        name: "2-임성윤",
        class: "경영학과 00학번",
        url: "",
      },
      {
        name: "2-박소연",
        class: "경영학과 01학번",
        url: "",
      },
      {
        name: "2-문언경",
        class: "경제학과 01학번",
        url: "",
      },
      {
        name: "2-정영모",
        class: "경제학과 02학번",
        url: "",
      },
      {
        name: "2-남상환",
        class: "상경계열 99학번",
        url: "",
      },
      {
        name: "2-허재성",
        class: "산업공학과 98학번",
        url: require(`../../asset/image/members/member_list/2기/2-허재성.jpeg`),
      },
      {
        name: "2-성기석",
        class: "전기전자학과 02학번",
        url: "",
      },
      {
        name: "2-정성문",
        class: "재료학과 02학번",
        url: require(`../../asset/image/members/member_list/2기/2-정성문.jpeg`),
      },
      {
        name: "2-장혜림",
        class: "독어독문학과 00학번",
        url: "",
      },
      {
        name: "2-우태규",
        class: "상경계열 99학번",
        url: require(`../../asset/image/members/member_list/2기/2-우태규.jpeg`),
      },
    ],
    3: [
      {
        name: "3-정일구",
        class: "경영학과 99학번",
        url: require(`../../asset/image/members/member_list/3기/3-정일구.jpeg`),
      },
      {
        name: "3-정신욱",
        class: "경영학과 99학번",
        url: require(`../../asset/image/members/member_list/3기/3-정신욱.jpeg`),
      },
      {
        name: "3-박준성",
        class: "경영학과 02학번",
        url: require(`../../asset/image/members/member_list/3기/3-박준성.jpeg`),
      },
      {
        name: "3-이충재",
        class: "경영학과 02학번",
        url: require(`../../asset/image/members/member_list/3기/3-이충재.jpeg`),
      },
    ],
    4: [
      {
        name: "4-강재구",
        class: "경제학과 04학번",
        url: require(`../../asset/image/members/member_list/4기/4-강재구.jpeg`),
      },
      {
        name: "4-임기택",
        class: "경영학과 04학번",
        url: require(`../../asset/image/members/member_list/4기/4-임기택.jpeg`),
      },
      {
        name: "4-김동일",
        class: "경영학과 04학번",
        url: require(`../../asset/image/members/member_list/4기/4-김동일.jpeg`),
      },
      {
        name: "4-노충석",
        class: "중어중문학과 99학번",
        url: "",
      },
      {
        name: "4-박민수",
        class: "전기전자공학과 04학번",
        url: require(`../../asset/image/members/member_list/4기/4-박민수.jpeg`),
      },
      {
        name: "4-서형석",
        class: "응용통계학과 03학번",
        url: require(`../../asset/image/members/member_list/4기/4-서형석.jpeg`),
      },
      {
        name: "4-우봉래",
        class: "법학과 03학번",
        url: require(`../../asset/image/members/member_list/4기/4-우봉래.jpeg`),
      },
      {
        name: "4-장승빈",
        class: "수학과 03학번",
        url: require(`../../asset/image/members/member_list/4기/4-장승빈.jpeg`),
      },
    ],
    5: [
      {
        name: "5-김현정",
        class: "정치외교학과 04학번",
        url: "",
      },
      {
        name: "5-장수용",
        class: "경제학과 05학번",
        url: require(`../../asset/image/members/member_list/5기/5-장수용.jpeg`),
      },
      {
        name: "5-송인성",
        class: "경영학과 04학번",
        url: "",
      },
      {
        name: "5-남기문",
        class: "경제학과 00학번",
        url: require(`../../asset/image/members/member_list/5기/5-남기문.jpeg`),
      },
      {
        name: "5-백상현",
        class: "경제학과 05학번",
        url: require(`../../asset/image/members/member_list/5기/5-백상현.jpeg`),
      },
      {
        name: "5-송윤규",
        class: "경영학과 학번",
        url: require(`../../asset/image/members/member_list/5기/5-송윤규.jpeg`),
      },
    ],
    6: [
      {
        name: "6-박지민",
        class: "경제학과 04학번",
        url: "",
      },
      {
        name: "6-신나라",
        class: "행정학과 05학번",
        url: "",
      },
      {
        name: "6-정하나",
        class: "응용통계학과 04학번",
        url: "",
      },
      {
        name: "6-고남열",
        class: "정보산업공학과 03학번",
        url: "",
      },
      {
        name: "6-이형섭",
        class: "경영학과 02학번",
        url: require(`../../asset/image/members/member_list/6기/6-이형섭.jpeg`),
      },
    ],
    7: [
      {
        name: "7-최준석",
        class: "경제학과 02학번",
        url: require(`../../asset/image/members/member_list/7기/7-최준석.jpeg`),
      },
      {
        name: "7-박종우",
        class: "경영학과 01학번",
        url: require(`../../asset/image/members/member_list/7기/7-박종우.jpeg`),
      },
      {
        name: "7-오예선",
        class: "영어영문학과 04학번",
        url: "",
      },
      {
        name: "7-권상진",
        class: "정보산업공학과 03학번",
        url: require(`../../asset/image/members/member_list/7기/7기_권상진.jpg`),
      },
      {
        name: "7-김예리",
        class: "경영학과 ",
        url: require(`../../asset/image/members/member_list/7기/7-김예리.jpeg`),
      },
    ],
    8: [
      {
        name: "8-장국민",
        class: "천문우주학과 01학번",
        url: require(`../../asset/image/members/member_list/8기/8-장국민.jpeg`),
      },
      {
        name: "8-장창규",
        class: "경영학과 02학번",
        url: require(`../../asset/image/members/member_list/8기/8-장창규.jpeg`),
      },
      {
        name: "8-방인혜",
        class: "경제학과 03학번",
        url: require(`../../asset/image/members/member_list/8기/8-방인혜.jpeg`),
      },
      {
        name: "8-허주현",
        class: "경제학과 03학번",
        url: require(`../../asset/image/members/member_list/8기/8-허주현.jpeg`),
      },
      {
        name: "8-김명성",
        class: "경영학과 02학번",
        url: "",
      },
      {
        name: "8-김형조",
        class: "경영학과 02학번",
        url: require(`../../asset/image/members/member_list/8기/8-김형조.jpeg`),
      },
    ],
    9: [
      {
        name: "9-김미현",
        class: "토목환경공학과 04학번",
        url: require(`../../asset/image/members/member_list/9기/9-김미현.jpeg`),
      },
      {
        name: "9-정연빈",
        class: "수학과 01학번",
        url: require(`../../asset/image/members/member_list/9기/9-정연빈.jpeg`),
      },
      {
        name: "9-이기수",
        class: "경영학과 03학번",
        url: require(`../../asset/image/members/member_list/9기/9-이기수.jpeg`),
      },
      {
        name: "9-권용현",
        class: "전기전자공학과 06학번",
        url: require(`../../asset/image/members/member_list/9기/9-권용현.jpeg`),
      },
      {
        name: "9-조연호",
        class: "경영학과 05학번",
        url: require(`../../asset/image/members/member_list/9기/9-조연호.jpeg`),
      },
      {
        name: "9-이준호",
        class: "컴퓨터산업공학과 02학번",
        url: require(`../../asset/image/members/member_list/9기/9-이준호.jpeg`),
      },
      {
        name: "9-유정민",
        class: "화학공학과 03학번",
        url: require(`../../asset/image/members/member_list/9기/9-유정민.jpeg`),
      },
      {
        name: "9-한상민",
        class: "정보산업공학과 02학번",
        url: require(`../../asset/image/members/member_list/9기/9-한상민.jpeg`),
      },
      {
        name: "9-이무진",
        class: "생명공학과 02학번",
        url: "",
      },
      {
        name: "9-이종택",
        class: "경영학과 06학번",
        url: require(`../../asset/image/members/member_list/9기/9-이종택.jpeg`),
      },
    ],
    10: [
      {
        name: "10-정형록",
        class: "경영학과 04학번",
        url: require(`../../asset/image/members/member_list/10기/10-정형록.png`),
      },
      {
        name: "10-정동은",
        class: "경제학과 06학번",
        url: require(`../../asset/image/members/member_list/10기/10-정동은.png`),
      },
      {
        name: "10-최승규",
        class: "정보산업공학과 02학번",
        url: require(`../../asset/image/members/member_list/10기/10-최승규.png`),
      },
      {
        name: "10-정승용",
        class: "문헌정보학과 03학번",
        url: require(`../../asset/image/members/member_list/10기/10-정승용.png`),
      },
      {
        name: "10-이재열",
        class: "경영학과 03학번",
        url: require(`../../asset/image/members/member_list/10기/10-이재열.png`),
      },
      {
        name: "10-정희성",
        class: "응용통계학과 03학번",
        url: require(`../../asset/image/members/member_list/10기/10-정희성.png`),
      },
      {
        name: "10-김태환",
        class: "경영학과 05학번",
        url: require(`../../asset/image/members/member_list/10기/10-김태환.png`),
      },
    ],
    11: [
      {
        name: "11-양수영",
        class: "응용통계학과 07학번",
        url: require(`../../asset/image/members/member_list/11기/11-양수영.jpeg`),
      },
      {
        name: "11-김재성",
        class: "국어국문학과 04학번",
        url: require(`../../asset/image/members/member_list/11기/11-김재성.jpeg`),
      },
      {
        name: "11-신소진",
        class: "경제학과 06학번",
        url: require(`../../asset/image/members/member_list/11기/11-신소진.jpeg`),
      },
      {
        name: "11-박영재",
        class: "상경계열 08학번",
        url: require(`../../asset/image/members/member_list/11기/11-박영재.jpeg`),
      },
      {
        name: "11-최민정",
        class: "경제학과 06학번",
        url: require(`../../asset/image/members/member_list/11기/11-최민정.jpeg`),
      },
      {
        name: "11-김종완",
        class: "경영학과 03학번",
        url: require(`../../asset/image/members/member_list/11기/11-김종완.jpeg`),
      },
      {
        name: "11-이석희",
        class: "경영학과 02학번",
        url: require(`../../asset/image/members/member_list/11기/11-이석희.jpeg`),
      },
      {
        name: "11-황중연",
        class: "경영학과 04학번",
        url: require(`../../asset/image/members/member_list/11기/11-황중연.jpeg`),
      },
      {
        name: "11-김태우",
        class: "경영학과 05학번",
        url: require(`../../asset/image/members/member_list/11기/11-김태우.jpeg`),
      },
      {
        name: "11-송치원",
        class: "경영학과 05학번",
        url: require(`../../asset/image/members/member_list/11기/11-송치원.jpeg`),
      },
    ],
    12: [
      {
        name: "12-정찬권",
        class: "상경계열 08학번",
        url: require(`../../asset/image/members/member_list/12기/12-정찬권.jpeg`),
      },
      {
        name: "12-김동훈",
        class: "전기전자학과 04학번",
        url: require(`../../asset/image/members/member_list/12기/12-김동훈.jpeg`),
      },
      {
        name: "12-김성철",
        class: "경영학과 03학번",
        url: require(`../../asset/image/members/member_list/12기/12-김성철.jpeg`),
      },
      {
        name: "12-김선미",
        class: "수학과 07학번",
        url: require(`../../asset/image/members/member_list/12기/12-김선미.jpeg`),
      },
      {
        name: "12-여주혜",
        class: "전기전자공학과 07학번",
        url: require(`../../asset/image/members/member_list/12기/12-여주혜.jpeg`),
      },
      {
        name: "12-나민형",
        class: "경영학과 04학번",
        url: require(`../../asset/image/members/member_list/12기/12-나민형.jpeg`),
      },
      {
        name: "12-이광희",
        class: "경영학과 05학번",
        url: require(`../../asset/image/members/member_list/12기/12-이광희.jpeg`),
      },
      {
        name: "12-강신형",
        class: "응용통계학과 06학번",
        url: require(`../../asset/image/members/member_list/12기/12-강신형.jpeg`),
      },
      {
        name: "12-이영일",
        class: "경제학과 07학번",
        url: require(`../../asset/image/members/member_list/12기/12-이영일.jpeg`),
      },
      {
        name: "12-한승수",
        class: "의학과 04학번",
        url: require(`../../asset/image/members/member_list/12기/12-한승수.jpeg`),
      },
    ],
    13: [
      {
        name: "13-한상길",
        class: "의학과 05학번",
        url: "",
      },
      {
        name: "13-신창학",
        class: "화학공학과 03학번",
        url: "",
      },
      {
        name: "13-이영재",
        class: "경영학과 04학번",
        url: require(`../../asset/image/members/member_list/13기/13-이영재.jpeg`),
      },
      {
        name: "13-양상혁",
        class: "전기전자공학과 08학번",
        url: require(`../../asset/image/members/member_list/13기/13-양상혁.jpeg`),
      },
      {
        name: "13-박세라",
        class: "경제학과 07학번",
        url: "",
      },
      {
        name: "13-주청",
        class: "사회복지학과 05학번",
        url: require(`../../asset/image/members/member_list/13기/13-주청.jpeg`),
      },
      {
        name: "13-김영범",
        class: "전기전자공학과 08학번",
        url: require(`../../asset/image/members/member_list/13기/13-김영범.jpeg`),
      },
      {
        name: "13-박준웅",
        class: "수학과 03학번",
        url: require(`../../asset/image/members/member_list/13기/13-박준웅.jpeg`),
      },
      {
        name: "13-임정훈",
        class: "경제학과 05학번",
        url: require(`../../asset/image/members/member_list/13기/13-임정훈.jpeg`),
      },
      {
        name: "13-변은영",
        class: "경영학과 06학번",
        url: require(`../../asset/image/members/member_list/13기/13-변은영.jpeg`),
      },
      {
        name: "13-변석준",
        class: "자유전공 09학번",
        url: require(`../../asset/image/members/member_list/13기/13-변석준.jpeg`),
      },
    ],
    14: [
      {
        name: "14-서형교",
        class: "정치외교/경제학과 01학번",
        url: require(`../../asset/image/members/member_list/14기/14-서형교.jpeg`),
      },
      {
        name: "14-김나연",
        class: "경영학과 09학번",
        url: "",
      },
      {
        name: "14-김효근",
        class: "상경계열 09학번",
        url: require(`../../asset/image/members/member_list/14기/14-김효근.jpeg`),
      },
      {
        name: "14-남은영",
        class: "교육학과 08학번",
        url: "",
      },
      {
        name: "14-김종수",
        class: "경제학과 04학번",
        url: require(`../../asset/image/members/member_list/14기/14-김종수.jpeg`),
      },
      {
        name: "14-구창모",
        class: "경영학과 05학번",
        url: require(`../../asset/image/members/member_list/14기/14-구창모.jpeg`),
      },
      {
        name: "14-김호철",
        class: "경영학과 05학번",
        url: require(`../../asset/image/members/member_list/14기/14-김호철.jpeg`),
      },
      {
        name: "14-임성빈",
        class: "경제학과 03학번",
        url: require(`../../asset/image/members/member_list/14기/14-임성빈.jpeg`),
      },
      {
        name: "14-이진영",
        class: "경영학과 09학번",
        url: "",
      },
      {
        name: "14-구태호",
        class: "경제학과 06학번",
        url: require(`../../asset/image/members/member_list/14기/14-구태호.jpeg`),
      },
      {
        name: "14-정태우",
        class: "경영학과 07학번",
        url: require(`../../asset/image/members/member_list/14기/14-정태우.jpeg`),
      },
    ],
    15: [
      {
        name: "15-권형우",
        class: "국어국문학과 05학번",
        url: require(`../../asset/image/members/member_list/15기/15-권형우.jpeg`),
      },
      {
        name: "15-김주영",
        class: "경제학과 08학번",
        url: require(`../../asset/image/members/member_list/15기/15-김주영.jpeg`),
      },
      {
        name: "15-김현선",
        class: "경영학과 05학번",
        url: "",
      },
      {
        name: "15-박새누리",
        class: "사회체육학과 08학번",
        url: require(`../../asset/image/members/member_list/15기/15-박새누리.jpeg`),
      },
      {
        name: "15-박종욱",
        class: "경제학과 05학번",
        url: require(`../../asset/image/members/member_list/15기/15-박종욱.jpeg`),
      },
      {
        name: "15-안세하",
        class: "경영학과 05학번",
        url: require(`../../asset/image/members/member_list/15기/15-안세하.jpeg`),
      },
      {
        name: "15-이석형",
        class: "경영학과 05학번",
        url: require(`../../asset/image/members/member_list/15기/15-이석형.jpeg`),
      },
      {
        name: "15-이연종",
        class: "정치외교학과 06학번",
        url: require(`../../asset/image/members/member_list/15기/15-이연종.jpeg`),
      },
      {
        name: "15-정다이",
        class: "경제학과 08학번",
        url: require(`../../asset/image/members/member_list/15기/15-정다이.jpeg`),
      },
      {
        name: "15-조용래",
        class: "경제학과 10학번",
        url: require(`../../asset/image/members/member_list/15기/15-조용래.jpeg`),
      },
      {
        name: "15-홍승미",
        class: "경제학과 08학번",
        url: require(`../../asset/image/members/member_list/15기/15-홍승미.jpeg`),
      },
    ],
    16: [
      {
        name: "16-이정수",
        class: "신학과 08학번",
        url: require(`../../asset/image/members/member_list/16기/16-이정수.jpeg`),
      },
      {
        name: "16-김태운",
        class: "전기전자학과 08학번",
        url: require(`../../asset/image/members/member_list/16기/16-김태운.jpeg`),
      },
      {
        name: "16-이동준",
        class: "경제학과 06학번",
        url: require(`../../asset/image/members/member_list/16기/16-이동준.jpeg`),
      },
      {
        name: "16-한정우",
        class: "경제학과 06학번",
        url: require(`../../asset/image/members/member_list/16기/16-한정우.jpeg`),
      },
      {
        name: "16-서혜연",
        class: "경제학과 08학번",
        url: "",
      },
      {
        name: "16-박태준",
        class: "경제학과 07학번",
        url: "",
      },
      {
        name: "16-박지효",
        class: "경제학과 07학번",
        url: require(`../../asset/image/members/member_list/16기/16-박지효.jpeg`),
      },
      {
        name: "16-권순민",
        class: "경영학과 07학번",
        url: require(`../../asset/image/members/member_list/16기/16-권순민.jpeg`),
      },
      {
        name: "16-서영재",
        class: "경제학과 10학번",
        url: "",
      },
      {
        name: "16-박찬웅",
        class: "신소재공학과 05학번",
        url: require(`../../asset/image/members/member_list/16기/16-박찬웅.jpeg`),
      },
      {
        name: "16-천민우",
        class: "정보산업공학과 08학번",
        url: require(`../../asset/image/members/member_list/16기/16-천민우.jpeg`),
      },
      {
        name: "16-양새미",
        class: "경제학과 10학번",
        url: "",
      },
    ],
    17: [
      {
        name: "17-김체린",
        class: "UIC 09학번",
        url: require(`../../asset/image/members/member_list/17기/17-김체린.jpeg`),
      },
      {
        name: "17-김동하",
        class: "경제학과 05학번",
        url: require(`../../asset/image/members/member_list/17기/17-김동하.jpeg`),
      },
      {
        name: "17-이시안",
        class: "UIC 07학번",
        url: require(`../../asset/image/members/member_list/17기/17-이시안.jpeg`),
      },
      {
        name: "17-손지웅",
        class: "경영학과 05학번",
        url: require(`../../asset/image/members/member_list/17기/17-손지웅.jpeg`),
      },
      {
        name: "17-이진희",
        class: "경제학과 07학번",
        url: require(`../../asset/image/members/member_list/17기/17_이진희.jpeg`),
      },
      {
        name: "17-양현지",
        class: "영어영문학과 08학번",
        url: require(`../../asset/image/members/member_list/17기/17-양현지.jpeg`),
      },
      {
        name: "17-임수진",
        class: "UIC 08학번",
        url: require(`../../asset/image/members/member_list/17기/17기_임수진.jpg`),
      },
      {
        name: "17-조성연",
        class: "정보산업공학과 06학번",
        url: require(`../../asset/image/members/member_list/17기/17기_조성연.jpeg`),
      },
      {
        name: "17-이동창",
        class: "경영학과 07학번",
        url: "",
      },
      {
        name: "17-조정원",
        class: "도시공학과 07학번",
        url: "",
      },
      {
        name: "17-한지희",
        class: "UIC 09학번",
        url: require(`../../asset/image/members/member_list/17기/17기_한지희.jpg`),
      },
      {
        name: "17-김신현",
        class: "경영학과 07학번",
        url: require(`../../asset/image/members/member_list/17기/17-김신현.jpeg`),
      },
    ],
    18: [
      {
        name: "18-강동근",
        class: "UIC 08학번",
        url: require(`../../asset/image/members/member_list/18기/18기_강동근.jpg`),
      },
      {
        name: "18-이기원",
        class: "국어국문학과 07학번",
        url: "",
      },
      {
        name: "18-김신영",
        class: "경영학과 10학번",
        url: require(`../../asset/image/members/member_list/18기/18기_김신영.jpg`),
      },
      {
        name: "18-서덕하",
        class: "경영학과 07학번",
        url: require(`../../asset/image/members/member_list/18기/18-서덕하.jpeg`),
      },
      {
        name: "18-배준현",
        class: "경제학과 11학번",
        url: require(`../../asset/image/members/member_list/18기/18기_배준현.jpg`),
      },
      {
        name: "18-유병희",
        class: "기계공학과 04학번",
        url: require(`../../asset/image/members/member_list/18기/18기_유병희.jpg`),
      },
      {
        name: "18-윤현탁",
        class: "UIC 11학번",
        url: require(`../../asset/image/members/member_list/18기/18기_윤현탁.png`),
      },
      {
        name: "18-이재환",
        class: "정보산업공학과 06학번",
        url: require(`../../asset/image/members/member_list/18기/18기_이재환.jpg`),
      },
      {
        name: "18-이춘기",
        class: "경영학과 06학번",
        url: require(`../../asset/image/members/member_list/18기/18기_이춘기.jpeg`),
      },
      {
        name: "18-임수현",
        class: "경영학과 08학번",
        url: require(`../../asset/image/members/member_list/18기/18기_임수현.jpg`),
      },
      {
        name: "18-진보람",
        class: "경제학과 08학번",
        url: require(`../../asset/image/members/member_list/18기/18기_진보람.jpg`),
      },
    ],
    19: [
      {
        name: "19-김영길",
        class: "경영학과 08학번",
        url: require(`../../asset/image/members/member_list/19기/19기_김영길.jpg`),
      },
      {
        name: "19-김태훈",
        class: "문헌정보학과 07학번",
        url: require(`../../asset/image/members/member_list/19기/19기_김태훈.jpg`),
      },
      {
        name: "19-엄재훈",
        class: "전기전자학과 06학번",
        url: require(`../../asset/image/members/member_list/19기/19기_엄재훈.jpg`),
      },
      {
        name: "19-유지성",
        class: "경제학과 11학번",
        url: "",
      },
      {
        name: "19-이세진",
        class: "경제학과 08학번",
        url: require(`../../asset/image/members/member_list/19기/19기_이세진.jpg`),
      },
      {
        name: "19-임석우",
        class: "경영학과 08학번",
        url: require(`../../asset/image/members/member_list/19기/19기_임석우.jpg`),
      },
      {
        name: "19-하태열",
        class: "경영학과 08학번",
        url: require(`../../asset/image/members/member_list/19기/19기_하태열.jpg`),
      },
    ],
    20: [
      {
        name: "20-윤을정",
        class: "경제학과 11학번",
        url: "",
      },
      {
        name: "20-이한준",
        class: "경영학과 07학번",
        url: "",
      },
      {
        name: "20-조상현 ",
        class: "경영학과 07학번",
        url: "",
      },
      {
        name: "20-윤영진",
        class: "행정학과 09학번",
        url: require(`../../asset/image/members/member_list/20기/20-윤영진.png`),
      },
      {
        name: "20-여준영",
        class: "경제학과 09학번",
        url: require(`../../asset/image/members/member_list/20기/20-여준영.jpeg`),
      },
      {
        name: "20-신유희",
        class: "경제학과 11학번",
        url: "",
      },
      {
        name: "20-이민호",
        class: "UIC 11학번",
        url: "",
      },
      {
        name: "20-안지선",
        class: "사회복지학과 11학번",
        url: "",
      },
      {
        name: "20-김지웅",
        class: "경영학과 08학번",
        url: "",
      },
      {
        name: "20-윤용진",
        class: "학번",
        url: "",
      },
    ],
    21: [
      {
        name: "21-김지은",
        class: "행정학과 11학번",
        url: "",
      },
      {
        name: "21-김찬영",
        class: "경영학과 10학번",
        url: "",
      },
      {
        name: "21-노경탁",
        class: "전기전자학과 07학번",
        url: require(`../../asset/image/members/member_list/21기/21-노경탁.jpeg`),
      },
      {
        name: "21-박진한",
        class: "경제학과 09학번",
        url: require(`../../asset/image/members/member_list/21기/21-박진한.jpeg`),
      },
      {
        name: "21-심무곤",
        class: "경영학과 12학번",
        url: require(`../../asset/image/members/member_list/21기/21-심무곤.jpeg`),
      },
      {
        name: "21-이희건",
        class: "경영학과 08학번",
        url: "",
      },
      {
        name: "21-정재연",
        class: "실내건축학과 08학번",
        url: require(`../../asset/image/members/member_list/21기/21-정재연.jpeg`),
      },
      {
        name: "21-차명진",
        class: "사회학과 11학번",
        url: "",
      },
    ],
    22: [
      {
        name: "22-장육유",
        class: "경영학과 08학번",
        url: "",
      },
      {
        name: "22-이원기",
        class: "국어국문학과 11학번",
        url: require(`../../asset/image/members/member_list/22기/22-이원기.jpeg`),
      },
      {
        name: "22-김선경",
        class: "",
        url: "",
      },
      {
        name: "22-정소라",
        class: "행정학과 10학번",
        url: require(`../../asset/image/members/member_list/22기/22-정소라.png`),
      },
      {
        name: "22-박혜민",
        class: "경제학과 10학번",
        url: "",
      },
    ],
    23: [
      {
        name: "23-김제헌",
        class: "경제학과 10학번",
        url: require(`../../asset/image/members/member_list/23기/23-김제헌.jpeg`),
      },
      {
        name: "23-박규환",
        class: "물리학과 09학번",
        url: require(`../../asset/image/members/member_list/23기/23-박규환.jpeg`),
      },
      {
        name: "23-박세현",
        class: "수학과 09학번",
        url: require(`../../asset/image/members/member_list/23기/23-박세현.jpeg`),
      },
      {
        name: "23-서유일",
        class: "경제학과 11학번",
        url: "",
      },
  
      {
        name: "23-송치진",
        class: "경영학과 10학번",
        url: "",
      },
      {
        name: "23-윤석진",
        class: "경제학과 11학번",
        url: "",
      },
      {
        name: "23-이호영",
        class: "경제학과 10학번",
        url: require(`../../asset/image/members/member_list/23기/23-이호영.jpeg`),
      },
      {
        name: "23-주영훈",
        class: "경영학과 10학번",
        url: "",
      },
      {
        name: "23-전정욱",
        class: "경영학과 10학번",
        url: require(`../../asset/image/members/member_list/23기/23-전정욱.jpeg`),
      },
      {
        name: "23-황석준",
        class: "경제학과 12학번",
        url: "",
      },
    ],
    24: [
      {
        name: "24-유노아",
        class: "불어불문학과 11학번",
        url: "",
      },
      {
        name: "24-조현호",
        class: "응용통계대학원 11학번",
        url: "",
      },
      {
        name: "24-정혜진",
        class: "응용통계학과 13학번",
        url: "",
      },
      {
        name: "24-백유경",
        class: "경제학과 14학번",
        url: "",
      },
      {
        name: "24-남덕호",
        class: "경영학과 10학번",
        url: "",
      },
      {
        name: "24-윤용식",
        class: "경영학과 10학번",
        url: "",
      },
      {
        name: "24-한유준",
        class: "경제학과 11학번",
        url: "",
      },
      {
        name: "24-김지현",
        class: "경제학과 14학번",
        url: "",
      },
      {
        name: "24-박시형",
        class: "생물과학대학원 06학번",
        url: "",
      },
      {
        name: "24-권규범",
        class: "중어중문학과 11학번",
        url: require(`../../asset/image/members/member_list/24기/24-권규범.jpeg`),
      },
      {
        name: "24-이홍석",
        class: "경제학과 11학번",
        url: "",
      },
      {
        name: "24-구현경",
        class: "신문방송학과 11학번",
        url: require(`../../asset/image/members/member_list/24기/24-구현경.jpeg`),
      },
    ],
    25: [
      {
        name: "25-고규진",
        class: "독어독문학과 08학번",
        url: "",
      },
      {
        name: "25-김범준",
        class: "경영학과 12학번",
        url: "",
      },
      {
        name: "25-김승현",
        class: "경제학과 11학번",
        url: "",
      },
  
      {
        name: "25-김영채",
        class: "경영학과 13학번",
        url: "",
      },
      {
        name: "25-박소민",
        class: "경영학과 13학번",
        url: "",
      },
      {
        name: "25-심준우",
        class: "QRM 14학번",
        url: "",
      },
      {
        name: "25-안지은",
        class: "경영학과 12학번",
        url: "",
      },
      {
        name: "25-이현우",
        class: "QRM 14학번",
        url: "",
      },
      {
        name: "25-장영윤",
        class: "사회학과 10학번",
        url: "",
      },
      {
        name: "25-전유진",
        class: "불어불문학과 12학번",
        url: "",
      },
      {
        name: "25-정영훈",
        class: "행정학과 09학번",
        url: "",
      },
      {
        name: "25-최소영 ",
        class: "경영학과 13학번",
        url: "",
      },
      {
        name: "25-이현우",
        class: "QRM 14학번",
        url: "",
      },
    ],
    26: [
      {
        name: "26-이유진",
        class: "경영학과 13학번",
        url: "",
      },
      {
        name: "26-이준휘",
        class: "경제학과 12학번",
        url: "",
      },
      {
        name: "26-최승혁",
        class: "경영학과 08학번",
        url: "",
      },
      {
        name: "26-최희원",
        class: "불어불문하고가 12학번",
        url: "",
      },
      {
        name: "26-황예지",
        class: "의류환경학과 13학번",
        url: "",
      },
      {
        name: "26-황인호",
        class: "응용통계학과 12학번",
        url: "",
      },
    ],
    27: [
      {
        name: "27-양은창",
        class: "11학번",
        url: "",
      },
      {
        name: "27-김병국",
        class: "경제학과 12학번",
        url: "",
      },
      {
        name: "27-김준현",
        class: "경제학과 12학번",
        url: require(`../../asset/image/members/member_list/27기/27-김준현.jpeg`),
      },
      {
        name: "27-신현우",
        class: "경제학과 11학번",
        url: "",
      },
      {
        name: "27-정희원",
        class: "",
        url: "",
      },
    ],
  
    28: [
      {
        name: "28-김지윤",
        class: "경영학과 13학번",
        url: "",
      },
      {
        name: "28-전현우",
        class: "경제학과 13학번",
        url: "",
      },
      {
        name: "28-임지우",
        class: "경영학과 12학번",
        url: "",
      },
      {
        name: "28-이준희",
        class: "경영학과 12학번",
        url: "",
      },
      {
        name: "28-박주현",
        class: "노어노문학과 13학번",
        url: "",
      },
    ],
    29: [
      {
        name: "29-손일호",
        class: "경영학과 12학번",
        url: "",
      },
      {
        name: "29-김정헌",
        class: "경영학과 08학번",
        url: "",
      },
      {
        name: "29-전창익",
        class: "경영학과 12학번",
        url: require(`../../asset/image/members/member_list/29기/29-전창익.jpeg`),
      },
      {
        name: "29-조승제",
        class: "경영학과 11학번",
        url: require(`../../asset/image/members/member_list/29기/29-조승제.png`),
      },
      {
        name: "29-이세호",
        class: "문헌정보학과 11학번",
        url: "",
      },
      {
        name: "29-박세은",
        class: "경제학과 13학번",
        url: "",
      },
      {
        name: "29-염종원",
        class: "도시공학과 11학번",
        url: "",
      },
      {
        name: "29-손혜원",
        class: "경영학과 15학번",
        url: "",
      },
      {
        name: "29-김관희",
        class: "산업공학과 15학번",
        url: require(`../../asset/image/members/member_list/29기/29-김관희.jpeg`),
      },
      {
        name: "29-정현준",
        class: "경영학과 13학번",
        url: "",
      },
      {
        name: "29-김명묵",
        class: "경제학과 13학번",
        url: "",
      },
      {
        name: "29-연세리",
        class: "응용통계학과 15학번",
        url: "",
      },
      {
        name: "29-전창익",
        class: "심리학과 11학번",
        url: require(`../../asset/image/members/member_list/29기/29-전창익.jpeg`),
      },
      {
        name: "29-김지헤",
        class: "국제학과 15학번",
        url: "",
      },
    ],
    30: [
      {
        name: "30-박종현",
        class: "경영학과 12학번",
        url: "",
      },
      {
        name: "30-심규근",
        class: "경영학과 13학번",
        url: require(`../../asset/image/members/member_list/20기/20-심규근.jpeg`),
      },
      {
        name: "30-강재구",
        class: "경영학과 13학번",
        url: "",
      },
      {
        name: "30-강경훈",
        class: "경제학과 13학번",
        url: "",
      },
      {
        name: "30-정재훈",
        class: "경제학과 13학번",
        url: "",
      },
      {
        name: "30-정우성",
        class: "경제학과 14학번",
        url: require(`../../asset/image/members/member_list/20기/20-정우성.jpeg`),
      },
      {
        name: "30-왕형준",
        class: "경영학과 13학번",
        url: require(`../../asset/image/members/member_list/30기/30-왕형준.jpeg`),
      },
      {
        name: "30-김재호",
        class: "경제학과 13학번",
        url: "",
      },
    ],
    31: [
      {
        name: "31-권경호",
        class: "경영학과 14학번",
        url: "",
      },
      {
        name: "31-황희준",
        class: "행정학과 12학번",
        url: "",
      },
      {
        name: "31-김민석",
        class: "철학과 14학번",
        url: "",
      },
      {
        name: "31-김준서",
        class: "경제학과 13학번",
        url: require(`../../asset/image/members/member_list/31기/31-김준서.jpeg`),
      },
      {
        name: "31-금자원",
        class: "경제학과 15학번",
        url: require(`../../asset/image/members/member_list/31기/31-금자원.jpeg`),
      },
      {
        name: "31-송지연",
        class: "경제학과 15학번",
        url: require(`../../asset/image/members/member_list/31기/31-송지연.jpeg`),
      },
      {
        name: "31-유승현",
        class: "아동가족학과 13학번",
        url: require(`../../asset/image/members/member_list/31기/31-유승현.jpeg`),
      },
      {
        name: "31-최수영",
        class: "경제학과 15학번",
        url: require(`../../asset/image/members/member_list/31기/31-최수영.jpeg`),
      },
      {
        name: "31-진효정",
        class: "UIC QRM 15학번",
        url: "",
      },
      {
        name: "31-오영훈",
        class: "경제학과 15학번",
        url: "",
      },
      {
        name: "31-이승열",
        class: "경영학과 13학번",
        url: "",
      },
      {
        name: "31-정승원",
        class: "지구시스템과학과 17학번",
        url: "",
      },
      {
        name: "31-정원선",
        class: "산업공학과 15학번",
        url: "",
      },
      {
        name: "31-한상현",
        class: "의예과 18학번",
        url: "",
      },
    ],
    32: [
      {
        name: "32-이주혜",
        class: "UIC 14학번",
        url: "",
      },
      {
        name: "32-권재현",
        class: "정치외교/행정학과 13학번",
        url: "",
      },
      {
        name: "32-백미정",
        class: "국어국문학과 17학번",
        url: "",
      },
      {
        name: "32-양명섭",
        class: "사회복지학과 12학번",
        url: "",
      },
      {
        name: "32-조희승",
        class: "경영/수학학과",
        url: "",
      },
      {
        name: "32-정세영",
        class: "경제학과 15학번",
        url: "",
      },
      {
        name: "32-백지영",
        class: "사회/정치외교학과",
        url: "",
      },
      {
        name: "32-신훈식",
        class: "경제학과 15학번",
        url: "",
      },
    ],
    33: [
      {
        name: "33-정재호",
        class: "UIC 15학번",
        url: "",
      },
      {
        name: "33-김서영",
        class: "경제학과 15학번",
        url: "",
      },
      {
        name: "33-박주혁",
        class: "경제학과 15학번",
        url: "",
      },
      {
        name: "33-박현근",
        class: "경영학과 15학번",
        url: require(`../../asset/image/members/member_list/33기/33-박현근.jpeg`),
      },
      {
        name: "33-이미선",
        class: "경영학과 17학번",
        url: "",
      },
      {
        name: "33-이채연",
        class: "경영학과 16학번",
        url: "",
      },
      {
        name: "33-전은지",
        class: "응용통계학과 15학번",
        url: "",
      },
      {
        name: "33-정호찬",
        class: "UIC 13학번",
        url: "",
      },
      {
        name: "33-표주성",
        class: "경제학과 13학번",
        url: "",
      },
      {
        name: "33-한찬희",
        class: "경제학과 15학번",
        url: "",
      },
    ],
    34: [
      {
        name: "34-임주원",
        class: "행정학과 18학번",
        url: require(`../../asset/image/members/member_list/34기/34-임주원.jpeg`),
      },
      {
        name: "34-한승석",
        class: "경영하과 12학번",
        url: "",
      },
      {
        name: "34-배성조",
        class: "응용통계학과 14학번",
        url: require(`../../asset/image/members/member_list/34기/34-배성조.jpeg`),
      },
      {
        name: "34-박규현",
        class: "경영학과 14학번",
        url: "",
      },
      {
        name: "34-정윤재",
        class: "경영학과 17학번",
        url: require(`../../asset/image/members/member_list/34기/34-정윤재.jpeg`),
      },
      {
        name: "34-고종희",
        class: "토목환경공학과 13학번",
        url: "",
      },
      {
        name: "34-박영재",
        class: "행정학과 17학번",
        url: "",
      },
      {
        name: "34-이영서",
        class: "경영학과 16학번",
        url: "",
      },
      {
        name: "34-임수민",
        class: "행정학과 17학번",
        url: "",
      },
      {
        name: "34-전준성",
        class: "경영학과 17학번",
        url: "",
      },
      {
        name: "34-허정호",
        class: "UIC 14학번",
        url: require(`../../asset/image/members/member_list/34기/34-허정호.jpeg`),
      },
      {
        name: "34-정다운",
        class: "경제학과 14학번",
        url: "",
      },
    ],
    35: [
      {
        name: "35-윤지훈",
        class: "UIC 15학번",
        url: require(`../../asset/image/members/member_list/35기/35-윤지훈.jpeg`),
      },
      {
        name: "35-권성근",
        class: "응용통계학과 16학번",
        url: require(`../../asset/image/members/member_list/35기/35-권성근.jpeg`),
      },
      {
        name: "35-김응태",
        class: "경제학과 15학번",
        url: require(`../../asset/image/members/member_list/35기/35-김응태.jpeg`),
      },
      {
        name: "35-윤종문",
        class: "경영학과 12학번",
        url: require(`../../asset/image/members/member_list/35기/35-윤종문.jpeg`),
      },
      {
        name: " 35-장유정",
        class: "UIC 17학번",
        url: "",
      },
      {
        name: "35-명재민",
        class: "경영학과 16학번",
        url: "",
      },
      {
        name: "35-박치영",
        class: "응용통계학과 14학번",
        url: "",
      },
      {
        name: "35-박현재",
        class: "겨영학과 16학번",
        url: "",
      },
      {
        name: "35-백여름",
        class: "경제학과 17학번",
        url: "",
      },
      {
        name: "35-유승현",
        class: "경제학과 20학번",
        url: require(`../../asset/image/members/member_list/35기/35-유승현.jpeg`),
      },
      {
        name: "35-김정범",
        class: "정치외교학과 13학번",
        url: require(`../../asset/image/members/member_list/35기/35-김정범.png`),
      },
      {
        name: "35-정세영",
        class: "중어중문학과 13학번",
        url: "",
      },
    ],
  
    36: [
      {
        name: "36-노동현",
        class: "경제학과 14학번",
        url: "",
      },
      {
        name: "36-김재원",
        class: "사학과 14학번",
        url: require(`../../asset/image/members/member_list/36기/36-김재원.jpeg`),
      },
      {
        name: "36-최정윤",
        class: "경제학과 17학번",
        url: "",
      },
      {
        name: "36-여신영",
        class: "UIC JCL 17학번",
        url: "",
      },
      {
        name: "36-신희규",
        class: "경영학과 15학번",
        url: "",
      },
      {
        name: "36-박건률",
        class: "정치외교학과 16학번",
        url: require(`../../asset/image/members/member_list/36기/36-박건률.jpeg`),
      },
      {
        name: "36-황성민",
        class: "경제학과 18학번",
        url: "",
      },
      {
        name: "36-김정현",
        class: "경제학과 17학번",
        url: require(`../../asset/image/members/member_list/36기/36-김정현.jpeg`),
      },
      {
        name: "36-박제민",
        class: "경영학과 16학번",
        url: require(`../../asset/image/members/member_list/36기/36-박제민.jpeg`),
      },
      {
        name: "36-신성호",
        class: "경제학과 18학번",
        url: "",
      },
      {
        name: "36-안현균",
        class: "경제학과 15학번",
        url: "",
      },
      {
        name: "36-전성현",
        class: "약학과 16학번",
        url: "",
      },
      {
        name: "36-정혜원",
        class: "UIC QRM 17학번",
        url: require(`../../asset/image/members/member_list/36기/36-정혜원.jpeg`),
      },
      {
        name: "36-최정우",
        class: "경영학과 16학번",
        url: "",
      },
    ],
  
    37: [
      {
        name: "37-권성모",
        class: "경제학과 20학번",
        url: "",
      },
      {
        name: "37-권시훤",
        class: "경영/경제학과 19학번",
        url: require(`../../asset/image/members/member_list/37기/37-권시훤.jpeg`),
      },
      {
        name: "37-권주혁",
        class: "UIC 18학번",
        url: "",
      },
      {
        name: "37-김채윤",
        class: "계량위험관리학과 18학번",
        url: "",
      },
      {
        name: "37-노신원",
        class: "식품영양학과 17학번",
        url: "",
      },
      {
        name: "37-임미현",
        class: "경제학과 19학번",
        url: "",
      },
      {
        name: "37-박재상",
        class: "경영학과 16학번",
        url: "",
      },
      {
        name: "37-배주희",
        class: "UIC 17학번",
        url: "",
      },
      {
        name: "37-김선욱",
        class: "UIC 17학번",
        url: "",
      },
      {
        name: "37-송현주",
        class: "불어불문학과 19학번",
        url: "",
      },
      {
        name: "37-안영진",
        class: "UIC 15학번",
        url: "",
      },
      {
        name: "37-윤형식",
        class: "경영학과 19학번",
        url: require(`../../asset/image/members/member_list/37기/37-윤형식.jpeg`),
      },
      {
        name: "37-이다건",
        class: "심리학과 19학번",
        url: "",
      },
      {
        name: "37-이우성",
        class: "경제학과 18학번",
        url: "",
      },
      {
        name: "37-이재석",
        class: "경영학과 18학번",
        url: "",
      },
      {
        name: "37-이재웅",
        class: "노어노문학과 17학번",
        url: require(`../../asset/image/members/member_list/37기/37-이재웅.jpeg`),
      },
      {
        name: "37-임진혁",
        class: "경영학과 19학번",
        url: require(`../../asset/image/members/member_list/37기/37-임진혁.jpeg`),
      },
      {
        name: "37-황재성",
        class: "경제학과 15힉번",
        url: require(`../../asset/image/members/member_list/37기/37-황재성.jpeg`),
      },
    ],
    38: [
      {
        name: "38-김근령",
        class: "언론홍보영상학과",
        url: "",
      },
      {
        name: "38-김유범",
        class: "16학번",
        url: "",
      },
      {
        name: "38-김종현",
        class: "",
        url: "",
      },
      {
        name: "38-김현우",
        class: "경영학과 17학번",
        url: "",
      },
      {
        name: "38-김휘도",
        class: "경제학과 17학번",
        url: "",
      },
      {
        name: "38-박순호",
        class: "경영학과 17학번",
        url: "",
      },
      {
        name: "38-서형인",
        class: "경제학과 ",
        url: "",
      },
      {
        name: "38-오문수",
        class: "경영학과",
        url: "",
      },
      {
        name: "38-이민지",
        class: "경영학과 18학번",
        url: "",
      },
      {
        name: "38-이윤재",
        class: "경제학과 ",
        url: "",
      },
      {
        name: "38-이종학",
        class: "경영학과 14학번",
        url: "",
      },
      {
        name: "38-주회정 ",
        class: "철학과 ",
        url: "",
      },
      {
        name: "38-진휘",
        class: "산업공학과 17학번 ",
        url: "",
      },
    ],
    39: [
      {
        name: "39-김민석",
        class: "응용통계학과",
        url: require(`../../asset/image/members/member_list/39기/39-김민석.png`),
      },
      {
        name: "39-남승현",
        class: "경영학과",
        url: require(`../../asset/image/members/member_list/39기/39-남승현.png`),
      },
      {
        name: "39-박현우",
        class: "경제학과",
        url: require(`../../asset/image/members/member_list/39기/39-박현우.png`),
      },
      {
        name: "39-서인욱",
        class: "",
        url: require(`../../asset/image/members/member_list/39기/39-서인욱.png`),
      },
      {
        name: "39-김준엽",
        class: "",
        url: "",
      },
      {
        name: "39-김형원",
        class: "",
        url: "",
      },
      {
        name: "39-박성우",
        class: "경영학과",
        url: "",
      },
      {
        name: "39-차지민",
        class: "경제학과",
        url: "",
      },
      {
        name: "39-채강원",
        class: "",
        url: "",
      },
      {
        name: "39-최희지",
        class: "",
        url: "",
      },
    ],
    40: [
      {
        name: "40-김형민",
        class: "UIC 20학번",
        url: require(`../../asset/image/members/member_list/40기/40-김형민.png`),
      },
      {
        name: "40-박정민",
        class: "QRM 17학번",
        url: require(`../../asset/image/members/member_list/40기/40-박정민.png`),
      },
      {
        name: "40-윤희재",
        class: "경영학과 17학번",
        url: require(`../../asset/image/members/member_list/40기/40-윤희재.png`),
      },
      {
        name: "40-김채현",
        class: "경제학과 19학번",
        url: "",
      },
      {
        name: "40-김한결",
        class: "19학번",
        url: "",
      },
      {
        name: "40-신혜찰",
        class: "경제학과 17학번",
        url: "",
      },
      {
        name: "40-윤태경",
        class: "경제학과 17학번",
        url: "",
      },
      {
        name: "40-이완희",
        class: "경영학과 15학번",
        url: "",
      },
      {
        name: "40-이우진",
        class: "경제학과 18학번",
        url: "",
      },
      {
        name: "40-정주원(부)",
        class: "영어영문학과 17학번",
        url: "",
      },
      {
        name: "40-유현우",
        class: "qrm 19학번",
        url: "",
      },
    ],
    41: [
      {
        name: "41-고다영",
        class: "",
        url: require(`../../asset/image/members/member_list/41기/41-고다영.png`),
      },
      {
        name: "41-김일곤",
        class: "경영학과 18학번",
        url: require(`../../asset/image/members/member_list/41기/41-김일곤.png`),
      },
      {
        name: "41-김준석",
        class: "철학과 19학번",
        url: require(`../../asset/image/members/member_list/41기/41-김준석.png`),
      },
      {
        name: "41-김준영",
        class: "경영학과 20학번",
        url: require(`../../asset/image/members/member_list/41기/41-김준영.png`),
      },
      {
        name: "41-배정재",
        class: "법학과 22학번",
        url: require(`../../asset/image/members/member_list/41기/41-배정재.png`),
      },
      {
        name: "41-배희조",
        class: "UIC 19학번",
        url: require(`../../asset/image/members/member_list/41기/41-배희조.png`),
      },
      {
        name: "41-유수민",
        class: "교육학과 20학번",
        url: require(`../../asset/image/members/member_list/41기/41-유수민.png`),
      },
      {
        name: "41-이상민",
        class: "경제학과 20학번",
        url: require(`../../asset/image/members/member_list/41기/41-이상민.png`),
      },
      {
        name: "41-이준범",
        class: "UIC 17학번",
        url: require(`../../asset/image/members/member_list/41기/41-이준범.png`),
      },
      {
        name: "41-전수경",
        class: "QRM 21학번",
        url: require(`../../asset/image/members/member_list/41기/41-전수경.png`),
      },
      {
        name: "41-황희원",
        class: "경영학과 20학번",
        url: require(`../../asset/image/members/member_list/41기/41-황희원.png`),
      },
      {
        name: "41-류재쥰",
        class: "경제학과 19학번",
        url: "",
      },
      {
        name: "41-박성우",
        class: "수학과 17학번",
        url: "",
      },
    ],
    42: [
      {
        name: "42-박재연",
        class: "",
        url: require(`../../asset/image/members/member_list/42기/42-박재연.png`),
      },
      {
        name: "42-박정하",
        class: "",
        url: require(`../../asset/image/members/member_list/42기/42-박정하.png`),
      },
      {
        name: "42-신승민",
        class: "",
        url: require(`../../asset/image/members/member_list/42기/42-신승민.jpg`),
      },
      {
        name: "42-양현경",
        class: "",
        url: require(`../../asset/image/members/member_list/42기/42-양현경.png`),
      },
      {
        name: "42-양희승",
        class: "",
        url: require(`../../asset/image/members/member_list/42기/42-양희승.png`),
      },
      {
        name: "42-윤재혁",
        class: "",
        url: require(`../../asset/image/members/member_list/42기/42-윤재혁.png`),
      },
      {
        name: "42-이상윤",
        class: "",
        url: require(`../../asset/image/members/member_list/42기/42-이상윤.png`),
      },
      {
        name: "42-이지원",
        class: "",
        url: require(`../../asset/image/members/member_list/42기/42-이지원.png`),
      },
      {
        name: "42-주민성",
        class: "",
        url: require(`../../asset/image/members/member_list/42기/42-주민성.png`),
      },
    ],
    43: [
      {
        name: "43-강예찬",
        class: "",
        url: require(`../../asset/image/members/member_list/43기/43-강예찬.png`),
      },
      {
        name: "43-김동완",
        class: "",
        url: require(`../../asset/image/members/member_list/43기/43-김동완.jpg`),
      },
      {
        name: "43-김은성",
        class: "",
        url: require(`../../asset/image/members/member_list/43기/43-김은성.jpg`),
      },
      {
        name: "43-김재윤",
        class: "",
        url: require(`../../asset/image/members/member_list/43기/43-김재윤.jpg`),
      },
      {
        name: "43-김준식",
        class: "",
        url: require(`../../asset/image/members/member_list/43기/43-김준식.png`),
      },
      {
        name: "43-남기범",
        class: "",
        url: require(`../../asset/image/members/member_list/43기/43-남기범.jpg`),
      },
      {
        name: "43-박진혁",
        class: "",
        url: require(`../../asset/image/members/member_list/43기/43-박진혁.png`),
      },
      {
        name: "43-오예준",
        class: "",
        url: require(`../../asset/image/members/member_list/43기/43-오예준.png`),
      },
      {
        name: "43-윤종혁",
        class: "",
        url: require(`../../asset/image/members/member_list/43기/43-윤종혁.jpg`),
      },
      {
        name: "43-이성은",
        class: "",
        url: require(`../../asset/image/members/member_list/43기/43-이성은.jpg`),
      },
      {
        name: "43-이정민",
        class: "",
        url: require(`../../asset/image/members/member_list/43기/43-이정민.jpg`),
      },
      {
        name: "43-한지수",
        class: "",
        url: require(`../../asset/image/members/member_list/43기/43-한지수.jpg`),
      },
      {
        name: "43-황재빈",
        class: "",
        url: require(`../../asset/image/members/member_list/43기/43-황재빈.jpg`),
      },
    ],
  };
  