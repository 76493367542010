import React, {useEffect, useState} from 'react'
import './LoginPage.css';
import banner from '../../asset/image/login/banner.svg';
import { useHistory } from 'react-router';

const LoginPage = () => {
    const history = useHistory();

    const [bodyHeight, setBodyHeight] = useState(0);

    const moveToSignUp = () => {
        history.push('/signup');
    }

    useEffect(() => {
        const myDom = document.querySelector(".body");
        if(myDom !== null){
            setBodyHeight(myDom.clientHeight);
        }
    }, [])

    return (
        <div>
            <div className='image-box'>
                <img alt='' src={banner} />
                <div className='community-text'>
                </div>
            </div>


            <div className="container">
      
        <h1>login</h1>
        <span style={{marginBottom: '100px'}}>
            YIG 멤버들의 공간입니다<br />더 많은 정보를 이용하려면 로그인이
            필요합니다</span>

        <ul className="links">
            <li>
            <a id="signin">SIGN IN</a>
            </li>
            <li>
            <a id="signup" onClick={moveToSignUp}>SIGN UP</a>
            </li>
        </ul>
        
        <button className="google__btn">
            <i className="fa fa-google"></i>
            Sign in with Google
        </button>
    </div>

        </div>
    
    )

    // return (
    //     <div className='login' style={{height: bodyHeight + 'px'}}>
    //         <div className='login-box'>
    //             <div className='login-left-box'>
    //                 <div className='sign-in-button'>SIGN IN</div>
    //             </div>
    //             <div className='login-right-box'>
    //                 <div className='login-right-title'>Sign Up</div>
    //                 <input className='login-input' placeholder='User' />
    //                 <input className='login-input' placeholder='Email' />
    //                 <input className='login-input' placeholder='Password' />
    //                 <div className='sign-up-button'>SIGN UP</div>
    //             </div>
    //         </div>
    //     </div>
    // )
};

export default LoginPage
