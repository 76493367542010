import React from 'react';
import AuthContextProvider from "./context/AuthContext";
import Template from "./Template";

const App = () => { 
    return (
        <AuthContextProvider>
            <Template />
        </AuthContextProvider>
     );
}

export default App;
