import * as React from "react";
import { createRef, RefObject } from "react";
import "./MainPage_6.css";
import President from "../../asset/image/main/president.png";
import "./MainPage_4.css";
import Banner2 from "../../asset/image/main/1bdad602437d7dc0bfe319909e982c69.jpg";
import main_6_1 from "../../asset/image/main/main-6-1.svg";
import main_6_2 from "../../asset/image/main/main6_2.svg";
import main_6_3 from "../../asset/image/main/main6_2_1.svg";
import main_6_4 from "../../asset/image/main/main6_3.svg";
import main_6_5 from "../../asset/image/main/main6_4.svg";
import main_6_6 from "../../asset/image/main/main6_5.svg";

import { MainProfile } from "./MainPage_3";
import IntersectionObserverManager from "../../IntersectionObserverManager";
import { ScrollAnimationContainer } from "../about/ScrollAnimationContainer";

export default class MessageFromPresident extends React.Component<{}> {
  private intersectionObserver?: IntersectionObserverManager;
  private messageWrapper: RefObject<HTMLDivElement> | null;

  constructor(props: {}) {
    super(props);
    this.messageWrapper = createRef<HTMLDivElement>();
  }

  public componentDidMount(): void {
    if (this.messageWrapper) {
      this.setIntersectionObserverAndSetSlideRight(this.messageWrapper);
    }
  }

  public render() {
    return (
      <div
        className={"mainPageSectionWrapper"}
        style={{
          position: "relative",
          backgroundImage: `url(${Banner2})`,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <div className="bottom6-up">
          <ScrollAnimationContainer>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  maxWidth: "400px",
                  width: "60%",
                }}
                src={main_6_1}
                alt=""
              />
            </div>
          </ScrollAnimationContainer>
        </div>

        <div className="bottom6">
          <ScrollAnimationContainer>
            <div
              style={{
                width: "80%",
                display: "flex",
                justifyContent: "center",
                margin: "0 0 20px 0",
              }}
            >
              <img className="image6" src={main_6_2} alt="" />
            </div>
          </ScrollAnimationContainer>
          <ScrollAnimationContainer>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                margin: "0 0 20px 0",
              }}
            >
              <img className="image6" src={main_6_3} alt="" />
            </div>
          </ScrollAnimationContainer>
          <ScrollAnimationContainer>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                margin: "0 0 20px 0",
              }}
            >
              <img className="image6" src={main_6_4} alt="" />
            </div>
          </ScrollAnimationContainer>
          <ScrollAnimationContainer>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                margin: "0 0 20px 0",
              }}
            >
              <img className="image6" src={main_6_5} alt="" />
            </div>
          </ScrollAnimationContainer>
          <ScrollAnimationContainer>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                margin: "0 0 0 0",
              }}
            >
              <img className="image6" src={main_6_6} alt="" />
            </div>
          </ScrollAnimationContainer>
        </div>
      </div>
    );
  }

  private setIntersectionObserverAndSetSlideRight = (
    ref: RefObject<HTMLDivElement>
  ) => {
    const observer = new IntersectionObserverManager((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          entry.target.classList.add("slideRight");
        } else {
          entry.target.classList.remove("slideRight");
        }
      });
    });

    this.intersectionObserver = observer;
    if (ref.current) {
      this.intersectionObserver.observe(ref.current);
    }
  };
}
