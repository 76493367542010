import React, {useEffect, useState} from 'react'
import './SignUpPage.css';
import banner from '../../asset/image/login/banner.svg';
import { useHistory } from 'react-router';

const LoginPage = () => {
    const history = useHistory();

    const moveToLogin = () => {
        history.push('/login');
    }

    useEffect(() => {
    }, [])

    return (
        <div>
            <div className='image-box'>
                <img alt='' src={banner} />
                <div className='community-text'>
                </div>
            </div>
            <div id="wrapper">
          
          <div style={{width: '460px', margin: '0 auto'}}>
                <h1>login</h1>
                <span style={{marginBottom: '100px'}}>
                    YIG 멤버들의 공간입니다<br />더 많은 정보를 이용하려면 로그인이
                    필요합니다</span>

                <ul className="links" style={{padding: 0}}>
                    <li>
                    <a id="signin" onClick={moveToLogin}>SIGN IN</a>
                    </li>
                    <li>
                    <a id="signup">SIGN UP</a>

                    </li>
                </ul>
          </div>
            
        
          <div id="content">
            
            <div>
              <h3 className="join_title">
                <label htmlFor="id">아이디</label>
              </h3>
              <span className="box int_id">
                <input type="text" id="id" className="int" maxLength={20} />
                <span className="step_url">@gmail.com</span>
              </span>
              <span className="error_next_box"></span>
            </div>
    
        
            <div>
              <h3 className="join_title"><label htmlFor="pswd1">비밀번호</label></h3>
              <span className="box int_pass">
                <input type="text" id="pswd1" className="int" maxLength={20} />
                <span id="alertTxt">사용불가</span>
              </span>
              <span className="error_next_box"></span>
            </div>
    
            
            <div>
              <h3 className="join_title"><label htmlFor="pswd2">비밀번호 재확인</label></h3>
              <span className="box int_pass_check">
                <input type="text" id="pswd2" className="int" maxLength={20} />
              </span>
              <span className="error_next_box"></span>
            </div>
    
            
            <div>
              <h3 className="join_title"><label htmlFor="name">이름</label></h3>
              <span className="box int_name">
                <input type="text" id="name" className="int" maxLength={20} />
              </span>
              <span className="error_next_box"></span>
            </div>
    
            
            <div>
              <h3 className="join_title"><label htmlFor="yy">생년월일</label></h3>
    
              <div id="bir_wrap">
                
                <div id="bir_yy">
                  <span className="box">
                    <input
                      type="text"
                      id="yy"
                      className="int"
                      maxLength={4}
                      placeholder="년(4자)"
                    />
                  </span>
                </div>
    
            
                <div id="bir_mm">
                  <span className="box">
                    <select id="mm" className="sel">
                      <option>월</option>
                      <option value="01">1</option>
                      <option value="02">2</option>
                      <option value="03">3</option>
                      <option value="04">4</option>
                      <option value="05">5</option>
                      <option value="06">6</option>
                      <option value="07">7</option>
                      <option value="08">8</option>
                      <option value="09">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </select>
                  </span>
                </div>
    
                
                <div id="bir_dd">
                  <span className="box">
                    <input
                      type="text"
                      id="dd"
                      className="int"
                      maxLength={2}
                      placeholder="일"
                    />
                  </span>
                </div>
              </div>
              <span className="error_next_box"></span>
            </div>
    
            
            <div>
              <h3 className="join_title"><label htmlFor="gender">성별</label></h3>
              <span className="box gender_code">
                <select id="gender" className="sel">
                  <option>성별</option>
                  <option value="M">남자</option>
                  <option value="F">여자</option>
                </select>
              </span>
              <span className="error_next_box">필수 정보입니다.</span>
            </div>
    
            
            <div>
              <h3 className="join_title"><label htmlFor="name">학과</label></h3>
              <span className="box int_name">
                <input
                  type="text"
                  id="name"
                  className="int"
                  maxLength={20}
                  placeholder="학과명을 정확하게 입력하시오"
                />
              </span>
              <span className="error_next_box"></span>
            </div>
    
            <div>
              <h3 className="join_title"><label htmlFor="name">학번</label></h3>
              <span className="box int_name">
                <input
                  type="text"
                  id="name"
                  className="int"
                  maxLength={20}
                  placeholder="학번을 입력하시오"
                />
              </span>
              <span className="error_next_box"></span>
            </div>
    
            <div>
              <h3 className="join_title"><label htmlFor="name">기수</label></h3>
              <span className="box int_name">
                <input
                  type="text"
                  id="name"
                  className="int"
                  maxLength={20}
                  placeholder="기수를 입력하시오"
                />
              </span>
              <span className="error_next_box"></span>
            </div>
    
            
            <div>
              <h3 className="join_title"><label htmlFor="phoneNo">휴대전화</label></h3>
              <span className="box int_mobile">
                <input
                  type="tel"
                  id="mobile"
                  className="int"
                  maxLength={16}
                  placeholder="전화번호를 -없이 입력하시오"
                />
              </span>
              <span className="error_next_box"></span>
            </div>
    
            
            <div className="btn_area">
              <button type="button" id="btnJoin" style={{color: 'white'}}>
                <span>가입하기</span>
              </button>
            </div>
          </div>
        
        </div>

        </div>
        
    )
};

export default LoginPage



// var id = document.querySelector("#id");

// var pw1 = document.querySelector("#pswd1");
// var pwMsg = document.querySelector("#alertTxt");
// var pwImg1 = document.querySelector("#pswd1_img1");

// var pw2 = document.querySelector("#pswd2");
// var pwImg2 = document.querySelector("#pswd2_img1");
// var pwMsgArea = document.querySelector(".int_pass");

// var userName = document.querySelector("#name");

// var yy = document.querySelector("#yy");
// var mm = document.querySelector("#mm");
// var dd = document.querySelector("#dd");

// var gender = document.querySelector("#gender");

// var email = document.querySelector("#email");

// var mobile = document.querySelector("#mobile");

// var error = document.querySelectorAll(".error_next_box");

// /*이벤트 핸들러 연결*/

// id.addEventListener("focusout", checkId);
// pw1.addEventListener("focusout", checkPw);
// pw2.addEventListener("focusout", comparePw);
// userName.addEventListener("focusout", checkName);
// yy.addEventListener("focusout", isBirthCompleted);
// mm.addEventListener("focusout", isBirthCompleted);
// dd.addEventListener("focusout", isBirthCompleted);
// gender.addEventListener("focusout", function () {
//   if (gender.value === "성별") {
//     error[5].style.display = "block";
//   } else {
//     error[5].style.display = "none";
//   }
// });
// email.addEventListener("focusout", isEmailCorrect);
// mobile.addEventListener("focusout", checkPhoneNum);

// /*콜백 함수*/

// function checkId() {
//   var idPattern = /[a-zA-Z0-9_-]{5,20}/;
//   if (id.value === "") {
//     error[0].innerHTML = "필수 정보입니다.";
//     error[0].style.display = "block";
//   } else if (!idPattern.test(id.value)) {
//     error[0].innerHTML =
//       "5~20자의 영문 소문자, 숫자와 특수기호(_),(-)만 사용 가능합니다.";
//     error[0].style.display = "block";
//   } else {
//     error[0].innerHTML = "멋진 아이디네요!";
//     error[0].style.color = "#08A600";
//     error[0].style.display = "block";
//   }
// }

// function checkPw() {
//   var pwPattern = /[a-zA-Z0-9~!@#$%^&*()_+|<>?:{}]{8,16}/;
//   if (pw1.value === "") {
//     error[1].innerHTML = "필수 정보입니다.";
//     error[1].style.display = "block";
//   } else if (!pwPattern.test(pw1.value)) {
//     error[1].innerHTML = "8~16자 영문 대 소문자, 숫자, 특수문자를 사용하세요.";
//     pwMsg.innerHTML = "사용불가";
//     pwMsgArea.style.paddingRight = "93px";
//     error[1].style.display = "block";

//     pwMsg.style.display = "block";
//     pwImg1.src = "m_icon_not_use.png";
//   } else {
//     error[1].style.display = "none";
//     pwMsg.innerHTML = "안전";
//     pwMsg.style.display = "block";
//     pwMsg.style.color = "#03c75a";
//     pwImg1.src = "m_icon_safe.png";
//   }
// }

// function comparePw() {
//   if (pw2.value === pw1.value && pw2.value != "") {
//     pwImg2.src = "m_icon_check_enable.png";
//     error[2].style.display = "none";
//   } else if (pw2.value !== pw1.value) {
//     pwImg2.src = "m_icon_check_disable.png";
//     error[2].innerHTML = "비밀번호가 일치하지 않습니다.";
//     error[2].style.display = "block";
//   }

//   if (pw2.value === "") {
//     error[2].innerHTML = "필수 정보입니다.";
//     error[2].style.display = "block";
//   }
// }

// function checkName() {
//   var namePattern = /[a-zA-Z가-힣]/;
//   if (userName.value === "") {
//     error[3].innerHTML = "필수 정보입니다.";
//     error[3].style.display = "block";
//   } else if (
//     !namePattern.test(userName.value) ||
//     userName.value.indexOf(" ") > -1
//   ) {
//     error[3].innerHTML =
//       "한글과 영문 대 소문자를 사용하세요. (특수기호, 공백 사용 불가)";
//     error[3].style.display = "block";
//   } else {
//     error[3].style.display = "none";
//   }
// }

// function isBirthCompleted() {
//   var yearPattern = /[0-9]{4}/;

//   if (!yearPattern.test(yy.value)) {
//     error[4].innerHTML = "태어난 년도 4자리를 정확하게 입력하세요.";
//     error[4].style.display = "block";
//   } else {
//     isMonthSelected();
//   }

//   function isMonthSelected() {
//     if (mm.value === "월") {
//       error[4].innerHTML = "태어난 월을 선택하세요.";
//     } else {
//       isDateCompleted();
//     }
//   }

//   function isDateCompleted() {
//     if (dd.value === "") {
//       error[4].innerHTML = "태어난 일(날짜) 2자리를 정확하게 입력하세요.";
//     } else {
//       isBirthRight();
//     }
//   }
// }

// function isBirthRight() {
//   var datePattern = /\d{1,2}/;
//   if (
//     !datePattern.test(dd.value) ||
//     Number(dd.value) < 1 ||
//     Number(dd.value) > 31
//   ) {
//     error[4].innerHTML = "생년월일을 다시 확인해주세요.";
//   } else {
//     checkAge();
//   }
// }

// function isEmailCorrect() {
//   var emailPattern = /[a-z0-9]{2,}@[a-z0-9-]{2,}\.[a-z0-9]{2,}/;

//   if (email.value === "") {
//     error[6].style.display = "none";
//   } else if (!emailPattern.test(email.value)) {
//     error[6].style.display = "block";
//   } else {
//     error[6].style.display = "none";
//   }
// }

// function checkPhoneNum() {
//   var isPhoneNum = /([01]{2})([01679]{1})([0-9]{3,4})([0-9]{4})/;

//   if (mobile.value === "") {
//     error[7].innerHTML = "필수 정보입니다.";
//     error[7].style.display = "block";
//   } else if (!isPhoneNum.test(mobile.value)) {
//     error[7].innerHTML = "형식에 맞지 않는 번호입니다.";
//     error[7].style.display = "block";
//   } else {
//     error[7].style.display = "none";
//   }
// }
