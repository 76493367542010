import * as React from 'react';
import main_1 from "../../asset/image/yig/curriculum/main-1.svg";
import main_2 from "../../asset/image/yig/curriculum/main-2.svg";
import main_3 from "../../asset/image/yig/curriculum/main-3.svg";
import {ScrollAnimationContainer} from "./ScrollAnimationContainer";

const Curriculum = () => {
    return (
        <div style={{maxWidth: '1500px', margin: '0 auto'}}>
            <ScrollAnimationContainer>
                <div style={{display: 'flex', justifyContent: 'center', margin: '80px 0 40px 0'}}>
                    <img style={{width: '100%'}} src={main_1} alt='' />
                </div>  
            </ScrollAnimationContainer>
            <ScrollAnimationContainer>
                <div style={{display: 'flex', justifyContent: 'center', margin: '80px 0 40px 0'}}>
                    <img style={{width: '100%'}} src={main_2} alt='' />
                </div> 
            </ScrollAnimationContainer>
            <ScrollAnimationContainer>
                <div style={{display: 'flex', justifyContent: 'center', margin: '80px 0 40px 0'}}>
                    <img style={{width: '100%'}} src={main_3} alt='' />
                </div>  
            </ScrollAnimationContainer>
                          
        </div>
    ) 
}

export default Curriculum;