import Gnb from "./Gnb";
import {useHistory} from "react-router-dom";
import React, {useRef, useState} from 'react'
import './Header.css';
import Logo from '../../asset/image/logo.svg';
import down_arrow from '../../asset/image/icon/down-arrow.svg';
import Logo_Text from '../../asset/image/logo-text.svg';

const Header = () => {
    const ref = useRef<HTMLDivElement | null>(null);
    const history = useHistory();
    const [hamburger, setHamburger] = useState(false);
    const [about, setAbout] = useState(false);

    const moveTo = (path: string) => {
        history.push(path);
        setHamburger(false);
    }

    return (
        <div ref={ref} className={'headerWrapper'} >
            <div className={'mainHeader'}>
                <div className={'logo'} onClick={() => {
                    window.location.href = '/';
                }}>
                    <img className="logo-main" src={Logo} alt="" />
                    <img className="logo-text" src={Logo_Text} alt="" />
                </div>
                <div className="hamburger" onClick={() => {
                    setHamburger(!hamburger);
                }}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className="hamburger-menu" style={
                    hamburger ? {
                        right: '0'
                    } : {
                        right: '-280px'
                    }
                    }>
                    <div className="h-item" onClick={() => moveTo('/')}>Home</div>
                    <div className="h-item" onClick={() => setAbout(!about)}>
                        About YIG
                        <img style={{width: '15px'}} src={down_arrow} alt="" />
                    </div>
                    <div className="drop-menu" style={
                    about ? {
                        height: '150px'
                    } : {
                        height: '0'
                    }
                    }>
                        <div onClick={() => moveTo('/yig/introduction')}>Introduction</div>
                        <div onClick={() => moveTo('/yig/curriculum')}>Curriculum</div>
                        <div onClick={() => moveTo('/yig/achievement')}>Achievement</div>
                    </div>
                    <div className="h-item" onClick={() => moveTo('/members')}>Members</div>
                    <div className="h-item" onClick={() => moveTo('/research')}>Research</div>
                    <div className="h-item" onClick={() => moveTo('/recruiting')}>Recruiting</div>
                    <div className="h-item" onClick={() => moveTo('/community')}>Community</div>
                    <div className="h-item" onClick={() => moveTo('/login')}>Login</div>
                </div>
                <Gnb />
            </div>
        </div>
    )
};




export default Header
