import * as React from "react";
import Introduction from "./Introduction";
import Curriculum from "./Curriculum";
import Achievement from "./Achievement";
import "./AboutYIG.css";

import { Route, Switch, withRouter } from "react-router-dom";
import banner from "../../asset/image/yig/Banner-1.svg";

const AboutYIG = () => {
  return (
    <div>
      <div className="image-box">
        <img alt="" src={banner} />
        <div className="about-title">
          <div>About YIG</div>
          <div style={{ fontSize: "20px", marginTop: "40px", fontWeight: 500 }}>
            YIG는 연세대학교의{" "}
            <span style={{ fontWeight: 700, display: "inline" }}>
              유일 실전가치투자학회
            </span>{" "}
            입니다.
          </div>
        </div>
      </div>
      <Switch>
        <Route
          exact={true}
          path={"/yig/introduction"}
          component={Introduction}
        />
        <Route exact={true} path={"/yig/curriculum"} component={Curriculum} />
        <Route exact={true} path={"/yig/achievement"} component={Achievement} />
      </Switch>
    </div>
  );
};

export default withRouter(AboutYIG);
