import * as React from 'react';
import main from "../../asset/image/yig/achievement/main.svg";
import {ScrollAnimationContainer} from "./ScrollAnimationContainer";
import './Achievement.css';

const Achievement = () => {
    return (
        <div style={{maxWidth: '1000px', margin: '0 auto'}}>
            <div style={{display: 'flex', justifyContent: 'center', margin: '100px 0 50px 0'}}>
                <img src={main} alt='' />
            </div>
            <ScrollAnimationContainer>
                <Row />
            </ScrollAnimationContainer>
        </div>
    ) 
}

export default Achievement;


const Row = () => {
    return (
        <div className='Row' style={{display: 'flex', width: '100%', justifyContent: 'center', gap: '50px', margin: '80px 20px 300px 20px'}}>
            <div className='row-title' style={{fontSize: '20px'}}>
                <div>YIG는 2003년에 설립한</div>
                <div>연세대학교 유일 실전 가치투자학회입니다.</div>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
                {arr.map((e, i) => (
                    <Item key={i} year={e.year} text={e.text} />
                ))}
            </div>
        </div>
    )
}

const Item = ({year, text}: {year: string, text: string}) => {
    return(
        <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
            <div style={{
                display: 'flex',
                visibility: year === '' ? 'hidden' : 'unset',
                alignItems: 'center',
                justifyContent: 'center',
                alignSelf: 'flex-start',
                borderRadius: '13px',
                fontWeight: 700,
                width: '138px',
                height: '47px',
                backgroundColor: '#DDEBF7',
                color: 'black'
            }}>{year}</div>
            <div style={{flex: 1}}>{text}</div>
        </div>
    )
}

const arr = [
    { year: '2024년', text: '제1회 비사이드코리아 가치투자분석공모전 3위'},
    { year: '2022년', text: 'KEYSS 리서치 대회 준우승'},
    { year: '2020년', text: '‘슈카월드‘ 출연'},
    { year: '', text: '연세대학교 Data Science Lab과 협업 체결, Data Finance팀 출범'},
    { year: '2018년', text: 'KEYSS 리서치 대회 준우승'},
    { year: '2017년', text: 'HYK 리서치 대회 우승'},
    { year: '', text: 'KEYSS 리서치 대회 준우승'},
    { year: '2015년', text: 'KEYSS 리서치 대회 우승'},
    { year: '2014년', text: 'SKYS 리서치대회 준우승'},
    { year: '', text: 'CFA International Research Competition 한국 대표 결선 진출'},
    { year: '2013년', text: 'SKYS 리서치 대회 우승'},
    { year: '', text: 'KEYS 리서치 대회 준우승'},
    { year: '2012년', text: 'SKYS 리서치대회 준우승'},
    { year: '', text: 'KEYS 리서치 대회 우승'},
    { year: '2011년', text: 'CFA협회주최 Global Investment Research Challenge 아시아 대회 진출'},
    { year: '2010년', text: 'CFA협회 주최 Global Investment Research Challenge 한국 대회 우승'},
    { year: '2009년', text: '제1회 대학생 리서치대회 우승'},
    { year: '2008년', text: '동양종합금융중권 1,4차 과제 우승'},
    { year: '2006년', text: '투자동아리 연합세션 주최'},
    { year: '2004년', text: 'YIG 펀드 정식 출범'},
    { year: '2004년', text: '㈜ FNGuide 프로젝트 수주'},
    { year: '2003년', text: '설립'},
]