import * as React from "react";
import { createRef, PropsWithChildren, RefObject } from "react";
// import Professor from '../../asset/image/main/professor.svg';
import Chairman from "../../asset/image/main/지도교수-한재훈.svg";
import { ScrollAnimationContainer } from "../about/ScrollAnimationContainer";

import Banner2 from "../../asset/image/main/main-3.svg";
import "./MainPage_3.css";
import "./Common.css";
import IntersectionObserverManager from "../../IntersectionObserverManager";

export default class MessageFromProfessor extends React.Component<{}> {
  private intersectionObserver?: IntersectionObserverManager;
  private messageWrapper: RefObject<HTMLDivElement> | null;

  constructor(props: {}) {
    super(props);
    this.messageWrapper = createRef<HTMLDivElement>();
  }

  public componentDidMount(): void {
    if (this.messageWrapper) {
      this.setIntersectionObserverAndSetSlideLeft(this.messageWrapper);
    }
  }

  public render() {
    return (
      <div
        className={"mainPageSectionWrapper"}
        style={{
          position: "relative",
          backgroundImage: `url(${Banner2})`,
          backgroundSize: "cover",
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          alignItems: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundPosition: "center center",
        }}
      >
        <div
          style={{
            display: "flex",
            backgroundColor: "#222A359C",
            padding: "20px",
          }}
          ref={this.messageWrapper}
          className={"messageWrapper"}
        >
          <MainProfile name={"한재훈"} src={Chairman} title={"지도교수"} />
          <MessageExampleMessage />
        </div>
      </div>
    );
  }
  private setIntersectionObserverAndSetSlideLeft = (
    ref: RefObject<HTMLDivElement>
  ) => {
    const observer = new IntersectionObserverManager((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          entry.target.classList.add("slideLeft");
        } else {
          entry.target.classList.remove("slideLeft");
        }
      });
    });

    this.intersectionObserver = observer;
    if (ref.current) {
      this.intersectionObserver.observe(ref.current);
    }
  };
}

export const MainProfile = (props: {
  src?: string;
  title?: string;
  name: string;
}) => (
  <div className={"mainProfile"}>
    <img className={"image"} src={props.src} />
    <div>
      <div className={"name"}>{props.name}</div>
      <div className={"title"}>{props.title}</div>
    </div>
  </div>
);

const MessageExampleMessage = () => (
  <div className="greeting">
    <p>
      변하는 시장 속에서 투자는 개인의 자산을 효과적으로 관리할 수 있는 주요
      방법 중 하나로 자리 잡았습니다.
    </p>
    <p>
      YIG는 연세대학교 유일의 실전 가치투자 학회로서 실제 기업 및 산업 분석과
      기업 가치평가 그리고 실제 펀드 운용을 통해 다양하고 선진화된 투자 방법과
      개념를 탐구합니다. 이렇게 스스로 투자 분석을 실행하고 토론하는 것은
      교실에서 배운 내용을 넘어 현업 실무 능력을 높이는 데 많은 도움이 될
      것입니다.
    </p>
    <p>
      많은 연세대학교 학생들이 YIG에서 이론을 넘은 실제 투자를 통해 보다 폭넓은
      배움을 경험해보기를 바랍니다.
    </p>
  </div>
);
