import * as React from "react";
import { createRef, PropsWithChildren, RefObject } from "react";
// import Professor from '../../asset/image/main/professor.svg';
import Chairman from "../../asset/image/main/신승민-42기-회장.svg";
import { ScrollAnimationContainer } from "../about/ScrollAnimationContainer";

import Banner2 from "../../asset/image/main/main-3.svg";
import "./MainPage_3.css";
import "./Common.css";
import IntersectionObserverManager from "../../IntersectionObserverManager";

export default class MessageFromProfessor extends React.Component<{}> {
  private intersectionObserver?: IntersectionObserverManager;
  private messageWrapper: RefObject<HTMLDivElement> | null;

  constructor(props: {}) {
    super(props);
    this.messageWrapper = createRef<HTMLDivElement>();
  }

  public componentDidMount(): void {
    if (this.messageWrapper) {
      this.setIntersectionObserverAndSetSlideLeft(this.messageWrapper);
    }
  }

  public render() {
    return (
      <div
        className={"mainPageSectionWrapper"}
        style={{
          position: "relative",
          backgroundImage: `url(${Banner2})`,
          backgroundSize: "cover",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundPosition: "center center",
        }}
      >
        <div
          style={{
            display: "flex",
            backgroundColor: "#222A359C",
            padding: "20px",
          }}
          ref={this.messageWrapper}
          className={"messageWrapper"}
        >
          <MainProfile name={"신승민"} src={Chairman} title={"YIG 42대 회장"} />
          <MessageExampleMessage />
        </div>
      </div>
    );
  }
  private setIntersectionObserverAndSetSlideLeft = (
    ref: RefObject<HTMLDivElement>
  ) => {
    const observer = new IntersectionObserverManager((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          entry.target.classList.add("slideLeft");
        } else {
          entry.target.classList.remove("slideLeft");
        }
      });
    });

    this.intersectionObserver = observer;
    if (ref.current) {
      this.intersectionObserver.observe(ref.current);
    }
  };
}

export const MainProfile = (props: {
  src?: string;
  title?: string;
  name: string;
}) => (
  <div className={"mainProfile"}>
    <img className={"image"} src={props.src} />
    <div>
      <div className={"name"}>{props.name}</div>
      <div className={"title"}>{props.title}</div>
    </div>
  </div>
);

const MessageExampleMessage = () => (
  <div className="greeting">
    <p>
      안녕하십니까, 연세대학교 실전 가치투자학회 YIG 42기 회장 신승민입니다.
    </p>
    <p>
      YIG는 2003년 설립된 연세대학교 유일의 실전 가치투자 학회입니다. 저희가
      지향하는 '가치투자'란 투자 대상의 내재가치를 깊게 분석하여 성과를 내는
      투자방식을 말합니다.
    </p>
    <p>
      YIG 학회원들은 깊이 있는 산업∙기업 분석을 통해 기업의 투자 포인트를
      발굴하고, 합리적인 매출∙비용 추정과 밸류에이션 과정을 통해 적정 주가를
      산출하여 투자 의견을 제시합니다. 더 나아가 학회원들은 이러한 분석들을
      토대로 1억 원 이상 규모의 YIG 펀드를 직접 운용하며 실전 경험을 쌓습니다.
    </p>
    <p>
      투자에 대한 흥미와 열정이 있으시다면 YIG는 언제나 여러분을 환영합니다.
      YIG는 여러분의 미래에 투자하겠습니다. 감사합니다.
    </p>
  </div>
);
