import Axios from 'axios';
import {BASE_URL} from "./config";
import LocalStorageManager from "../util/LocalStorageManager";


// const instance = Axios.create({
//     baseURL: BASE_URL,
// });

// let token;

// try {
//     token = LocalStorageManager.getItem('account').token
// } catch (e) {
//     token = null
// }

// if (token) {
//     instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
// }

// const unAuthorizedQueue = [];

// instance.interceptors.response.use((res) => res, async (err) => {
//     if (err.response != null && err.response.status === 401 && err.response.data.messages != null
//         && err.response.data.messages[0].message === 'Token is invalid or expired') {
//         if (instance.defaults.headers.common.Authorization == null) {
//             LocalStorageManager.removeItem('account');
//             return err.response
//         }

//         const reToken = await LocalStorageManager.getItem('account').refresh;
//         const response = await refreshToken(reToken);
//         const {access} = response.data;

//         const before = LocalStorageManager.getItem('account');
//         const account = before == null ? {token: access} : {...before, token: access};

//         LocalStorageManager.setItem('account', account);

//         const originalRequest = err.config;

//         if (originalRequest._retry) {
//             return
//         }

//         originalRequest._retry = true;

//         originalRequest.headers = {
//             ...originalRequest.headers,
//             "Authorization": `Bearer ${access}`
//         };

//         return instance(originalRequest);
//     }
// });

interface IUserDTO {
    id?: number,
    username: string
    password?: string,
    name: string,
    email: string,
    phone: string
    picture?: File,
    avatar?: File,
    is_member?: false,
    is_staff?: false,
    birthday: string,
    introduction?: string,
    created_at?: string,
    updated_at?: string,
    generation?: string
}

const getProfiles = () => {
    return null
    // return instance.get(`/profiles/`)
};

const getProfile = (id: number) => {
    return null
    // return instance.get(`/profiles/${id}`)
};

const createUser = (userDTO: IUserDTO) => {
    const formData = createForm(userDTO);
    return formData
    // return instance.post(`/profiles/`, formData)
};

const updateProfile = (id: number, userDTO: IUserDTO) => {
    return null
    // return instance.put(`/profiles/`, userDTO)
};

const deleteProfile = (id: number) => {
    return null
    // return instance.delete(`/profiles/${id}`)
};

const login = (username: string, password: string) => {
    return null
    // return instance.post('/token/', {username, password})
};

const refreshToken = (refreshToken: string) => {
    return null
    // return instance.post('/token/refresh/', {refresh: refreshToken})
};

const createPhoto = (file: File, description: string, album: string) => {
    const formData = new FormData();
    formData.append('photo', file);
    formData.append('description', description);
    formData.append('album', album);
    return null
    // return instance.post(`/photos/`,formData,{
    //     headers:{
    //         'Content-Type':'application/form-data'
    //     }
    // })  
};

export interface IPostDTO {
    id?: number,
    title: string,
    content: string,
    user: string,
    board: string,
    viewsCount?:number,
    commentsCount?: number,
    likesCount?: number,
    likes?: IUserDTO [],
    isNotice?: boolean,
    parent: IPostDTO,
    depth: number,
}

const getPost = (id: number) => {
    return null
    // return instance.get(`/posts/${id}/`)
};

const createPost = (postDTO: IPostDTO) => {
    return {"data": null}
    // return instance.put(`/posts/`, {post: postDTO})
};

const updatePost = (id: number, postDTO: IPostDTO) => {
    return {"data": null}
    // return instance.post(`/posts/${id}/`, {post: postDTO} )
};

const deletePost = (id: number) => {
    return {"data": null}
    // return instance.delete(`/posts/${id}/`)
};

const getPostImages = (id: number) => {
    return {"data": null}
    // return instance.get(`/posts/${id}`)
};

const createPostImage = (postId: number, name: string, image: File) => {
    const formData = new FormData();
    formData.append('post', postId.toString());
    formData.append('name', '');
    formData.append('image', image);
    return {"data": {}}
    // return instance.post(`/posts/${postId}`, formData)
};


const createForm = (value: Object): FormData => {
    const formData = new FormData();
    
    for(const key in value){
        // @ts-ignore
        formData.append(key, value[key])
    }
    
    return formData
};

const getReports = () => {
    return {"data": null}
    // return instance.get(`/reports/active/`)
};

const getFundReports = () => {
    return {"data": null}
    // return instance.get(`/fund_reports/active/`)
};

const getGenerations = () => {
    return {"data": null}
    // return instance.get(`/generations/sorted_generations`)
};

const getGenerationProfiles = (generation_id: number) => {
    return {"data": null}
    // return instance.get(`/generations/${generation_id}/generation_profiles/`)
};

const getRecruitingProcess = () => {
    return {"data": {"process_message": "hi", "description": "ey"}}
    // return instance.get(`/recruiting_processes/active`)
};

const getRecruitingCriterion = () => {
    return {"data": {}}
    // return instance.get(`/recruiting_criterions/active`)
};

const getRecruitingInformation = () => {
    return {"data": {}}
    // return instance.get(`/recruiting_informations/active`)
};

const getApplicationForm = () => {
    return {"data": {}}
    // return instance.get(`/application_forms/active_one`)
};

export default {
    getProfiles,
    createUser,
    updateProfile,
    deleteProfile,
    login,
    createPhoto,
    getPost,
    createPost,
    updatePost,
    deletePost,
    getPostImages,
    createPostImage,
    getReports,
    getFundReports,
    getGenerations,
    getGenerationProfiles,
    getRecruitingProcess,
    getRecruitingCriterion,
    getRecruitingInformation,
    getApplicationForm
}

