import * as React from "react";
import "./Recruiting.css";
import banner from "../../asset/image/recruiting/banner.svg";
import main_1 from "../../asset/image/recruiting/main-1.svg";
import main_2 from "../../asset/image/recruiting/main-2.svg";
import main_3 from "../../asset/image/recruiting/process.svg";
import main_4 from "../../asset/image/recruiting/Group 73.svg";

const Recruiting = () => {
  return (
    <div>
      <div className="recruiting-image-box">
        <img alt="" src={banner} />
        <div className="recruiting-text">Recruiting</div>
      </div>

      <div style={{ maxWidth: "1500px", margin: "0 auto" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "80px 0 300px 0",
          }}
        >
          <img style={{ width: "100%" }} src={main_1} alt="" />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "80px 0 200px 0",
          }}
        >
          <a href={url}>
            <img style={{ width: "100%" }} src={main_2} alt="" />
          </a>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "80px 0 70px 0",
          }}
        >
          <img style={{ width: "15%" }} src={main_3} alt="" />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "80px 0 300px 0",
          }}
        >
          <img style={{ width: "80%" }} src={main_4} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Recruiting;

const url =
  "https://storage.googleapis.com/ebitda/media/application_forms/2023-08/%5B%E1%84%92%E1%85%A9%E1%86%BC%E1%84%80%E1%85%B5%E1%86%AF%E1%84%83%E1%85%A9%E1%86%BC%5D%20YIG_%E1%84%8C%E1%85%B5%E1%84%8B%E1%85%AF%E1%86%AB%E1%84%89%E1%85%A5_2023-2_v2.docx";
